import {
    GET_LICENCES_TYPE,
    INIT_LICENCES_LIST,
    GET_LICENCES_BY_EXPAND,
    GET_LICENCES_BY_SEARCH
} from '../dataProvider/constant'

const DEFAULT_STATE = {
    currentPageNumber: 1,
    licencesTypeList: []
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case GET_LICENCES_TYPE:
            return { ...state, licencesTypeList: action.payload };
        case INIT_LICENCES_LIST:
            state.licencesTypeList = []
            return {
                ...state,
                licencesTypeList: action.payload,
                currentPageNumber: 1
            }
        case GET_LICENCES_BY_EXPAND:
            const licencesTypeList = [...state.licencesTypeList, ...action.payload]
            const updatedList = licencesTypeList.filter((obj, pos, arr) => {
                return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
            });
            return {
                ...state,
                licencesTypeList: updatedList,
                currentPageNumber: state.currentPageNumber + 1,
            }
        case GET_LICENCES_BY_SEARCH:
            return {
                ...state,
                licencesTypeList: action.payload,
                currentPageNumber: 1
            }
        default:
            return state;
    }
}