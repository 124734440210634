import {
  ORGANIZATION_GET_ORGANIZATION,
  INIT_ORGANIZATION_LIST,
  GET_ORGANIZATION_BY_EXPAND,
  GET_ORGANIZATION_BY_SEARCH
} from '../dataProvider/constant';

const DEFAULT_STATE = {
  organizations: [],
  currentPageNumber: 1,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ORGANIZATION_GET_ORGANIZATION:
      return {
        ...state,
        organizations: action.payload
      }

    case INIT_ORGANIZATION_LIST:
      state.organizations = []
      return {
        ...state,
        organizations: action.payload,
        currentPageNumber: 1
      }

    case GET_ORGANIZATION_BY_EXPAND:
      const organizations = [...state.organizations, ...action.payload]
      const updatedList = organizations.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
      });
      return {
        ...state,
        organizations: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }

    case GET_ORGANIZATION_BY_SEARCH:
      return {
        ...state,
        organizations: action.payload,
        currentPageNumber: 1
      }

    default:
      return state;
  }
}