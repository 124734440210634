import {
    GET_PDF_TEMPLATE,
    GET_PDF_TEMPLATE_DETAIL
} from '../dataProvider/constant';

const DEFAULT_STATE = {
    pdfTemplateList: [],
    pdfTemplateDetail: [],
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case GET_PDF_TEMPLATE:
            return { ...state, pdfTemplateList: action.payload };
        case GET_PDF_TEMPLATE_DETAIL:
            return { ...state, pdfTemplateDetail: action.payload }
        default:
            return state;
    }
}