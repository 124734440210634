import {
	ORG_USERS,
	ORG_USERS_PERMISSION
} from '../dataProvider/constant';

const DEFAULT_STATE = {
	users: [],
	displayPermiComp : false
}

export default (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case ORG_USERS:
			return{...state, users: action.payload};
		case ORG_USERS_PERMISSION:
			return{...state,displayPermiComp: action.displayPermiComp};
		default:
			return state;
	}
}