import {
  SUB_CATEGORY_GET_SUB_CATEGORIES,
  INIT_SUB_CATEGORIES_LIST,
  GET_SUB_CATEGORIES_BY_EXPAND,
  GET_SUB_CATEGORIES_BY_SEARCH
} from '../dataProvider/constant'

const DEFAULT_STATE = {
  subCategories: [],
  currentPageNumber: 1
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SUB_CATEGORY_GET_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload
      }

    case INIT_SUB_CATEGORIES_LIST:
      state.subCategories = []
      return {
        ...state,
        subCategories: action.payload,
        currentPageNumber: 1
      }

    case GET_SUB_CATEGORIES_BY_EXPAND:
      const subCategories = [...state.subCategories, ...action.payload]
      const updatedList = subCategories.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
      });
      return {
        ...state,
        subCategories: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }

    case GET_SUB_CATEGORIES_BY_SEARCH:
      return {
        ...state,
        subCategories: action.payload,
        currentPageNumber: 1
      }

    default:
      return state;
  }
}