import { MODULE_GET_MODULES, MODULE_URL, INIT_MODULES_LIST, GET_MODULES_BY_EXPAND, GET_MODULES_BY_SEARCH } from '../dataProvider/constant';
import { startSipnner, stopSipnner, startMiniSpinner, startScrollSpinner, stopMiniSpinner, stopScrollSpinner } from '../utils/spinner';
import axiosInstance from '../dataProvider/axiosHelper';
import { Strings } from '../dataProvider/localize';

const getAllModulesApi = dispatch => {
  return axiosInstance.get(MODULE_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: MODULE_GET_MODULES,
          payload: res.data.data.modules
        })
        return Promise.resolve(true)
      }

    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const getModules = () => dispatch => {
  startSipnner(dispatch);
  return getAllModulesApi(dispatch);

}

export const addModule = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(MODULE_URL, formData)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        initAllModulesApi(dispatch)
        return Promise.resolve(res.data.message)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateModule = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(MODULE_URL, formData)
    .then(res => {
      stopSipnner(dispatch)
      if (res.data.status) {
        getAllModulesApi(dispatch)
        return Promise.resolve(res.data.message)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const initModules = () => dispatch => {
  return initAllModulesApi(dispatch)
}

const initAllModulesApi = dispatch => {
  startSipnner(dispatch)

  const searchKey = '';
  const pageNumber = 1;

  const url = `${MODULE_URL}?search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INIT_MODULES_LIST,
          payload: res.data.data.modules
        })
        return Promise.resolve()
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const searchExpandModulesList = (searchKey = '', pageNumber = 1, searching, scrolling) => dispatch => {

  searching ? startMiniSpinner(dispatch) : scrolling ? startScrollSpinner(dispatch) : startSipnner(dispatch)

  const url = `${MODULE_URL}?search_key=${searchKey}&page=${pageNumber}`
  return axiosInstance.get(url)
    .then(res => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      if (res.data.status && !searching) {
        dispatch({
          type: GET_MODULES_BY_EXPAND,
          payload: res.data.data.modules,
        })
        return Promise.resolve(res.data.data.modules.length)
      } else {
        dispatch({
          type: GET_MODULES_BY_SEARCH,
          payload: res.data.data.modules,
        })
        return Promise.resolve(res.data.data.modules.length)
      }
    })
    .catch(error => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}
