import {
  SUB_CATEGORY_GET_SUB_CATEGORIES,
  SUB_CATEGORIES_URL,
  INIT_SUB_CATEGORIES_LIST,
  GET_SUB_CATEGORIES_BY_EXPAND,
  GET_SUB_CATEGORIES_BY_SEARCH
} from '../dataProvider/constant';
import {
  startSipnner, stopSipnner, startMiniSpinner, startScrollSpinner, stopMiniSpinner, stopScrollSpinner
} from '../utils/spinner';
import axiosInstance from '../dataProvider/axiosHelper';
import { Strings } from '../dataProvider/localize';

const getAllSubCategoriesApi = dispatch => {
  return axiosInstance.get(SUB_CATEGORIES_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: SUB_CATEGORY_GET_SUB_CATEGORIES,
          payload: res.data.data.sub_categories //map with categories during API integration
        })
        return Promise.resolve(true)
      }

    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const getSubCategories = () => dispatch => {
  startSipnner(dispatch);
  return getAllSubCategoriesApi(dispatch);

}

export const addSubCategory = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(SUB_CATEGORIES_URL, formData)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        initAllSubCategoriesApi(dispatch)
        return Promise.resolve(res.data.message)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateSubCategory = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(SUB_CATEGORIES_URL, formData)
    .then(async res => {
      stopSipnner(dispatch)
      if (res.data.status) {
        await getAllSubCategoriesApi(dispatch)
        return Promise.resolve(res.data.message)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const initSubCategories = () => dispatch => {
  return initAllSubCategoriesApi(dispatch)
}

const initAllSubCategoriesApi = dispatch => {
  startSipnner(dispatch)

  const searchKey = '';
  const pageNumber = 1;

  const url = `${SUB_CATEGORIES_URL}?search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INIT_SUB_CATEGORIES_LIST,
          payload: res.data.data.sub_categories
        })
        return Promise.resolve()
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const searchExpandSubCategoriesList = (searchKey = '', pageNumber = 1, searching, scrolling) => dispatch => {

  searching ? startMiniSpinner(dispatch) : scrolling ? startScrollSpinner(dispatch) : startSipnner(dispatch)

  const url = `${SUB_CATEGORIES_URL}?search_key=${searchKey}&page=${pageNumber}`
  return axiosInstance.get(url)
    .then(res => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      if (res.data.status && !searching) {
        dispatch({
          type: GET_SUB_CATEGORIES_BY_EXPAND,
          payload: res.data.data.sub_categories,
        })
        return Promise.resolve(res.data.data.sub_categories.length)
      } else {
        dispatch({
          type: GET_SUB_CATEGORIES_BY_SEARCH,
          payload: res.data.data.sub_categories,
        })
        return Promise.resolve(res.data.data.sub_categories.length)
      }
    })
    .catch(error => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}
