import { CATEGORY_GET_CATEGORIES, CATEGORIES_URL, INIT_CATEGORIES_LIST, GET_CATEGORIES_BY_EXPAND, GET_CATEGORIES_BY_SEARCH } from '../dataProvider/constant';
import { startSipnner, stopSipnner, startMiniSpinner, startScrollSpinner, stopMiniSpinner, stopScrollSpinner } from '../utils/spinner';
import axiosInstance from '../dataProvider/axiosHelper';
import { Strings } from '../dataProvider/localize';

const getAllCategoriesApi = dispatch => {
  return axiosInstance.get(CATEGORIES_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: CATEGORY_GET_CATEGORIES,
          payload: res.data.data.categories
        })
        return Promise.resolve(true)
      }

    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const getCategories = () => dispatch => {
  startSipnner(dispatch);
  return getAllCategoriesApi(dispatch);
}

export const addCategory = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(CATEGORIES_URL, formData)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        initAllCategoriesApi(dispatch)
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateCategory = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(CATEGORIES_URL, formData)
    .then(async res => {
      stopSipnner(dispatch)
      if (res.data.status) {
        await getAllCategoriesApi(dispatch)
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const initCategories = () => dispatch => {
  return initAllCategoriesApi(dispatch)
}

const initAllCategoriesApi = dispatch => {
  startSipnner(dispatch)

  const searchKey = '';
  const pageNumber = 1;

  const url = `${CATEGORIES_URL}?search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INIT_CATEGORIES_LIST,
          payload: res.data.data.categories
        })
        return Promise.resolve()
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const searchExpandCategoriesList = (searchKey = '', pageNumber = 1, searching, scrolling) => dispatch => {

  searching ? startMiniSpinner(dispatch) : scrolling ? startScrollSpinner(dispatch) : startSipnner(dispatch)

  const url = `${CATEGORIES_URL}?search_key=${searchKey}&page=${pageNumber}`
  return axiosInstance.get(url)
    .then(res => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      if (res.data.status && !searching) {
        dispatch({
          type: GET_CATEGORIES_BY_EXPAND,
          payload: res.data.data.categories,
        })
        return Promise.resolve(res.data.data.categories.length)
      } else {
        dispatch({
          type: GET_CATEGORIES_BY_SEARCH,
          payload: res.data.data.categories,
        })
        return Promise.resolve(res.data.data.categories.length)
      }
    })
    .catch(error => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}
