import validator from 'validator';
import { ValidationStrings } from './../../dataProvider/localize'

export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = ValidationStrings.subscription_validation_name
    }
    if (!values.period) {
        errors.period = ValidationStrings.subscription_validation_period
    } else if (typeof values.period === "string" && !validator.isIn(values.period, ["3", "1", "12"])) {
        errors.period = ValidationStrings.subscription_validation_valid_period
    }
    if (!values.amount) {
        errors.amount = ValidationStrings.subscription_validation_amount
    }
    return errors
}