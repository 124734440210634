import { ADDITIONAL_FIELDS_LIST, ADDITIONAL_FIELDS_CATEGORIES } from '../../dataProvider/constant';

const DEFAULT_STATE = {
  additionalFieldsList: [],
  totalFields: 0,
  fieldCategories: [],
}

export default (state = DEFAULT_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case ADDITIONAL_FIELDS_LIST:
      return {
        ...state,
        additionalFieldsList: payload.additionalFieldsList,
        totalFields: payload.fields_count
      }
      case ADDITIONAL_FIELDS_CATEGORIES:
      return {
        ...state,
        fieldCategories: payload,
      }
    default:
      return state;
  }
}