import React from 'react';
import { Icon, Collapse, Upload, Dropdown, notification } from 'antd';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { validate } from '../../../utils/Validations/organizationValidaton';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../common/custom-input';
import * as actions from '../../../actions/organizationAction';
import * as subscriptionAction from '../../../actions/subscriptionAction';
import { customTextarea } from '../../common/customTextarea';
import { CustomSelect } from '../../common/customSelect';
import { CustomDatepicker } from '../../common/customDatepicker';
import * as rolesActions from '../../../actions/roleManagementActions';
import FormData from 'form-data'
import { Strings } from '../../../dataProvider/localize'
import moment from 'moment';
import $ from 'jquery';
import * as orgUseraActions from "../../../actions/organizationUserAction";
import * as orgBillingActions from "../../../actions/organizationBillingAction";
import { countryCodes } from '../../../dataProvider/countryCodes';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { VALIDATE_STATUS } from '../../../dataProvider/constant'
import { handleFocus, DeepTrim } from '../../../utils/common';
import AddServiceAgentIndustry from './AddServiceAgentIndustry';


const Panel = Collapse.Panel;
const Dragger = Upload.Dragger;

class AddOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fileList: [], cardExpnadBtn: true, imageArray: [] }
    this.props.subscriptionAction.getSubscription();
    this.props.rolesActions.getRoles();
    this.sub_amount = 0;
  }

  beforeSubmit = () => {
    let values = this.props.formValues
    if (values && !this.state.cardExpnadBtn) {
      this.handleExpand()
    }
  }


  onSubmit = async (formData) => {
    formData = await DeepTrim(formData);

    //formData.subscription_start_date = formData.subscription_start_date ? formData.subscription_start_date.toString() : '';
    //formData.org_code = 'SK100';
    let finalFormData = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === 'org_users' || key === 'org_industries') {
        finalFormData.append(key, JSON.stringify(formData[key]));
      } else {
        finalFormData.append(key, formData[key]);
      }
    })

    if (this.state.imageArray.length > 0) {
      finalFormData.append('logo', this.state.imageArray[0]);
    }

    finalFormData.append('sub_amount', this.sub_amount)

    this.props.action.addOrganization(finalFormData).then((data) => {
      // this.props.reset();
      this.removeFile();
      notification.success({
        message: Strings.success_title,
        description: data && data.message ? data.message : Strings.generic_error, onClick: () => { },
        className: 'ant-success'
      });
      this.props.orgUseraActions.getOrganizationUsers(data.data.id).then(() => {
        this.props.history.push({
          pathname: "./showOrganization",
          state: data.data.id
        })
      })
      this.props.orgBillingActions.getOrganizationBillingDetails(data.data.id);
    }).catch((error) => {
      if (error.status === VALIDATE_STATUS) {
        notification.warning({
          message: Strings.validate_title,
          description: error && error.data && typeof error.data.message === 'string'
            ? error.data.message : Strings.generic_validate,
          onClick: () => { },
          className: 'ant-warning'
        });
      } else {
        notification.error({
          message: Strings.error_title,
          description: error && error.data && error.data.message && typeof error.data.message === 'string'
            ? error.data.message : Strings.generic_error,
          onClick: () => { },
          className: 'ant-error'
        });
      }
    });

  }



  renderMembers = ({ fields, meta: { error, submitFailed } }) => {
    if (fields.length === 0) {
      fields.push({})
    }
    return (
      <PerfectScrollbar className="sf-ps-scroll">
        <div className="sf-ps-scroll-content">
          <table className="add-user-table table">
          <thead>
            <tr>
              <td>{Strings.user_table_name}</td>
              <td>{Strings.user_table_email}</td>
              <td>{Strings.user_table_phone}</td>
              <td>{Strings.user_table_role}</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
            {fields.map((member, index) => {
              if (index === 0)
                this.props.change(`org_users[${index}].role_id`, this.props.roles.find(role => role.is_admin === 1)
                  && this.props.roles.find(role => role.is_admin === 1).id);
              return (
                <tbody key={index}>
                <tr>
                  <td>
                    <fieldset className="sf-form">
                      <Field
                        name={`${member}.name`}
                        placeholder={Strings.name_user_org}
                        type="text"
                        component={customInput}
                      />
                    </fieldset>
                  </td>
                  <td>
                    <fieldset className="sf-form">
                      <Field
                        name={`${member}.email_address`}
                        placeholder={Strings.email_address_org}
                        type="text"
                        component={customInput}
                      />
                    </fieldset>
                  </td>
                  <td>
                    <div className="co-code-mobile-no">
                      <fieldset className="sf-form co-code-txt">
                        <Field
                          name={`${member}.country_code`}
                          type="text"
                          showSearch={1}
                          defaultValue="+61"
                          options={countryCodes.map(country => ({
                            title: country.dial_code,
                            value: country.dial_code
                          }))}
                          component={CustomSelect}
                        />
                      </fieldset>
                      <fieldset className="sf-form mobile-ntxt">
                        <Field
                          name={`${member}.phone_number`}
                          placeholder={Strings.phone_number_user_org}
                          type="text"
                          maxLength="10"
                          component={customInput}
                        />
                      </fieldset>
                    </div>
                  </td>
                  <td>{index === 0 ?
                    this.props.roles && this.props.roles.find(role => role.is_admin === 1) ?
                      this.props.roles.find(role => role.is_admin === 1).name :
                      <fieldset className="sf-form">
                        <Field
                          name={`${member}.role_id`}
                          placeholder={Strings.role_id_user_org}
                          // type="text"
                          options={this.props.roles ? this.props.roles.map(role => ({ title: role.name, value: role.id })) : []}
                          component={CustomSelect}
                        />
                      </fieldset>
                    : <fieldset className="sf-form add-n-role-d">
                      <Field
                        name={`${member}.role_id`}
                        placeholder={Strings.role_id_user_org}
                        // type="text"
                        options={this.props.roles ? this.props.roles.map(role => ({ title: role.name, value: role.id })) : []}
                        component={CustomSelect}
                      />
                    </fieldset>
                  }</td>
                  <td>{index === 0 ? null :
                    <button className='delete-bnt' type='button' onClick={() => fields.remove(index)}><i className="fa fa-trash-o"></i></button>}
                  </td>
                </tr>
                </tbody>
              )
            })}
          </table>
          <div className="btn-hs-icon sm-bnt bnt-error">
            <button className="bnt bnt-normal" type="button" onClick={() => fields.push({ country_code: "+61", role_id: [] })}>{Strings.add_user_btn}</button>
            {submitFailed && error && <span className="error-input">{error}</span>}
          </div>
        </div>
      </PerfectScrollbar>
    )
  }

  removeFile = () => this.setState({ fileList: [], imageArray: [] });

  // expand center card----------

  handleExpand = () => {
    this.setState({ cardExpnadBtn: !this.state.cardExpnadBtn })
    $(".main-container").toggleClass("po-relative");
    $(".sf-card-wrap").toggleClass("fullscreen");
    $(".zoom-save-bnt").toggleClass("show-bnt");
  }


  calculateSubscriptionAmt = () => {
    if (this.props.formValues.subscription_id && this.props.formValues.sub_period && this.props.formValues.sub_period) {
      let subscription = this.props.subscriptions.find(
        subscript => subscript.subscription_id === this.props.formValues.subscription_id);
      if (subscription) {
        return subscription.amount - (((subscription.amount / subscription.period * this.props.formValues.sub_period)
          * this.props.formValues.sub_discount) / 100)
      }
    }
    if (this.props.formValues.subscription_id) {
      let subscription = this.props.subscriptions.find(
        subscript => subscript.subscription_id === this.props.formValues.subscription_id);
      if (subscription) {
        return subscription.amount
      }
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const uploadPicProps = {
      beforeUpload: file => {
        this.setState({
          fileList: [file],
        });
        return false;
      },
      multiple: false,
      onChange: (info) => {
        this.setState({ fileList: [info.fileList[info.fileList.length - 1]], imageArray: [info.file] })
      },
      listType: "picture",
      accept: ".jpeg,.jpg,.png",
      fileList: this.state.fileList,
      onRemove: this.removeFile

    };
    this.sub_amount = this.calculateSubscriptionAmt()

    return (

      <div className={this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-md-12 col-lg-8 mb-4">
              <div className="sf-card-wrap">
                {/* zoom button  */}
                <div className="card-expands">
                  <button type="button" onClick={this.handleExpand} className="exapnd-bnt normal-bnt">
                    <Icon type={this.state.cardExpnadBtn ? "fullscreen" : "fullscreen-exit"} /></button>
                </div>
                <div className="sf-card">
                  <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                    <h2 className="sf-pg-heading">{Strings.org_detail_card}</h2>
                    <div className="info-btn disable-dot-menu">
                      <Dropdown className="more-info" disabled overlay={''}>
                        <i className="material-icons">more_vert</i>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="sf-card-body mt-2">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-3 mb-3">
                        <div className="logo-upload">
                          <Dragger  {...uploadPicProps}>
                            <p className="ant-upload-drag-icon">
                              <i className="anticon material-icons">cloud_upload</i>
                            </p>
                            <p className="ant-upload-text">{Strings.img_upload_text}</p>
                          </Dragger>
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-9">
                        <div className="row">
                          <div className="col-md-12 col-lg-6">
                            <fieldset className="form-group sf-form">
                              <Field
                                label={Strings.org_name}
                                name="name"
                                placeholder={Strings.org_name}
                                type="text"
                                id=""
                                component={customInput} />
                            </fieldset>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <fieldset className="form-group sf-form">
                              <Field
                                label={Strings.org_pri_person}
                                name="primary_person"
                                placeholder={Strings.primary_person_org}
                                type="text"
                                id="primary_person"
                                component={customInput} />
                            </fieldset>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <fieldset className="form-group sf-form">
                              <Field
                                label={Strings.org_phone}
                                name="phone_number"
                                type="text"
                                id=""
                                component={customInput} />
                            </fieldset>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <fieldset className="form-group sf-form">
                              <Field
                                label={Strings.org_email}
                                name="email_address"
                                placeholder={Strings.email_address_org}
                                type="text"
                                id=""
                                component={customInput} />
                            </fieldset>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <fieldset className="form-group sf-form">
                              <Field
                                label={Strings.website_txt}
                                name="website"
                                type="text"
                                id=""
                                component={customInput} />
                            </fieldset>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <fieldset className="form-group sf-form">
                              <Field
                                label={Strings.org_abn}
                                name="abn_acn"
                                placeholder={Strings.abn_acn_org}
                                type="text"
                                id=""
                                component={customInput} />
                            </fieldset>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <fieldset className="form-group sf-form">
                              <Field
                                label={Strings.notification_email_txt}
                                name="notification_email"
                                placeholder={Strings.notification_email_sad}
                                type="text"
                                id=""
                                component={customInput} />
                            </fieldset>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <fieldset className="form-group sf-form">
                              <Field
                                label={Strings.org_address}
                                name="address"
                                placeholder={Strings.address_org}
                                type="text"
                                id="address"
                                component={customTextarea} />
                            </fieldset>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sf-card mt-4 sf-mcard a-sr-agent">
                  <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                    <h2 className="sf-pg-heading">{Strings.service_inductries_txt}</h2>
                    <div className="info-btn disable-dot-menu">
                      <Dropdown className="more-info" disabled overlay={''}>
                        <i className="material-icons">more_vert</i>
                      </Dropdown>
                    </div>
                  </div>

                  {/* INdustry */}
                  <FieldArray
                    name="org_industries" component={AddServiceAgentIndustry} change={this.props.change} />
                </div>

                <div className="sf-card mt-4">
                  <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                    <h2 className="sf-pg-heading">{Strings.bill_card}</h2>
                    <div className="info-btn disable-dot-menu">
                      <Dropdown className="more-info" disabled overlay={''}>
                        <i className="material-icons">more_vert</i>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="sf-card-body mt-2">

                    <div className="data-v-row">
                      <div className="data-v-col">
                        <div className="view-text-value">
                          <fieldset className="form-group sf-form">
                            <Field
                              label="Address"
                              name="billing_address"
                              placeholder={Strings.billing_address_org}
                              type="text"
                              id=""
                              component={customTextarea} />
                          </fieldset>
                        </div>
                      </div>

                      <div className="data-v-col">
                        <div className="view-text-value">
                          <fieldset className="form-group sf-form">
                            <Field
                              label="Email"
                              name="billing_email_address"
                              placeholder={Strings.email_address_org}
                              type="text"
                              id=""
                              component={customInput} />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    {/*}: <button className="bnt bnt-active" onClick={this.addBillingDetailsHandler}><i className="material-icons">credit_card</i> {Strings.add_pay_meth_title}</button>*/}
                  </div>
                </div>
                {/* add users section */}
                <div className="sf-card mt-4">
                  <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                    <h2 className="sf-pg-heading">{Strings.org_user_card}</h2>
                    <div className="info-btn disable-dot-menu">
                      <Dropdown className="more-info" disabled overlay={''}>
                        <i className="material-icons">more_vert</i>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="sf-card-body">
                    <FieldArray name="org_users" component={this.renderMembers} />
                  </div>
                </div>

                {/* zoom save button  */}

                <div className="row zoom-save-bnt">
                  <div className="col-md-12">
                    <div className="all-btn d-flex justify-content-end">
                      <div className="btn-hs-icon">
                        {/* <button type="submit" className="bnt bnt-active">
                          <i className="material-icons">save</i>{Strings.save_btn}</button> */}
                        <a href=';' onClick={this.beforeSubmit}>
                          <button type="submit" className="bnt bnt-active">
                            <i className="material-icons">save</i> {Strings.save_btn}
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            {/**Right side subscription dertails */}
            <div className="col-md-12 col-lg-4 mb-4">
              <div className="sf-card">
                <Collapse className="sf-collps-rt" defaultActiveKey={['1']} /* onChange={callback} */ expandIcon={({ isActive }) => <Icon type="caret-up" rotate={isActive ? 180 : 0} />}>
                  <Panel header={Strings.sub_card} key="1">
                    <div className="sf-card-body mt-2">

                      <fieldset className="sf-form form-group">
                        <Field name='subscription_id' label={Strings.sub_type} placeholder={Strings.subscription_id} type="text"
                          options={
                            this.props.subscriptions
                              // .filter(subscript => subscript.active === 1)
                              .map(subscript => ({
                                title: subscript.name, value: subscript.subscription_id,
                                disabled: !subscript.active
                              }))} component={CustomSelect} />
                      </fieldset>

                      <fieldset className="form-group sf-form lsico">
                        <Field
                          label={Strings.sub_invoice_date}
                          placeholder={moment(new Date()).format('YYYY-MM-DD')}
                          name="invoice_start_date"
                          component={CustomDatepicker} />
                      </fieldset>
                      <fieldset className="form-group sf-form">
                        <Field
                          label={Strings.sub_percentage}
                          name="sub_discount"
                          placeholder={Strings.sub_discount}
                          type="number"
                          id=""
                          component={customInput} />
                      </fieldset>
                      <fieldset className="form-group sf-form">
                        <Field
                          label={Strings.sub_discount_period}
                          name="sub_period"
                          placeholder={Strings.sub_period}
                          type="number"
                          id=""
                          component={customInput} />
                      </fieldset>
                      <div className="view-text-value mb-3">
                        <label>{Strings.sub_discount_amt}</label>
                        <span>${this.sub_amount ? this.sub_amount : 0}</span>
                      </div>
                      <div className="view-text-value mb-2">
                        <label>{Strings.sub_date}</label>
                        <span>{moment().format('DD MMM YYYY')}</span>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="all-btn d-flex justify-content-end">
                <div className="btn-hs-icon">
                  <button type="submit" className="bnt bnt-active">
                    <i className="material-icons">save</i>{Strings.save_btn}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organization.organizations,
    subscriptions: state.subscription.subscriptions,
    roles: state.roleManagement.roles,
    formValues: state.form.addOrganization && state.form.addOrganization.values ? state.form.addOrganization.values : {}
  }
}

const mapDispatchToprops = dispatch => {
  return {
    action: bindActionCreators(actions, dispatch),
    subscriptionAction: bindActionCreators(subscriptionAction, dispatch),
    rolesActions: bindActionCreators(rolesActions, dispatch),
    orgUseraActions: bindActionCreators(orgUseraActions, dispatch),
    orgBillingActions: bindActionCreators(orgBillingActions, dispatch),
    initialValues: {
      subscription_id: [],
      sub_discount: 0, sub_period: 0,
      org_users: [{ country_code: "+61", role_id: [] }]
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToprops),
  reduxForm({
    form: 'addOrganization', validate, enableReinitialize: true, onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    }
  })
)(AddOrganization)
