import React from 'react';
import { Icon, Menu, Dropdown, notification } from 'antd';
import { reduxForm, Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import $ from 'jquery';

import { validate } from '../../../utils/Validations/subscriptionValidate';
import { customInput } from '../../common/custom-input';
import { CustomSelect } from '../../common/customSelect';
import * as actions from '../../../actions/subscriptionAction';
import * as modulesAction from '../../../actions/moduleManagementAction';
import { Strings } from '../../../dataProvider/localize';
import { CustomCheckbox } from '../../common/customCheckbox';
import { CustomSwitch } from '../../common/customSwitch';
import { currencyFormat, handleFocus, DeepTrim } from '../../../utils/common';
import { VALIDATE_STATUS } from '../../../dataProvider/constant'


class ViewEditSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = { displayEdit: 'none', displayModulesEdit: 'none', cardExpnadBtn: true }
        this.props.modulesAction.getModules();
    }

    onSubmit = async formData => {
        formData = await DeepTrim(formData);

        formData.active = +formData.active;
        formData.org_visibility = +formData.org_visibility
        if (this.state.displayEdit === 'block') {
            this.props.action.updateSubscription(formData).then((message) => {
                this.handleCancel();
                notification.success({
                    message: Strings.success_title,
                    description: message,
                    onClick: () => { },
                    className: 'ant-success'
                });
            }).catch((error) => {
                if (error.status === VALIDATE_STATUS) {
                    notification.warning({
                        message: Strings.validate_title,
                        description: error && error.data && typeof error.data.message === 'string'
                            ? error.data.message : Strings.generic_validate,
                        onClick: () => { },
                        className: 'ant-warning'
                    });
                } else {
                    notification.error({
                        message: Strings.error_title,
                        description: error && error.data && error.data.message && typeof error.data.message === 'string'
                            ? error.data.message : Strings.generic_error,
                        onClick: () => { },
                        className: 'ant-error'
                    });
                }
            });
        } else {
            let finalFormData = new FormData();
            let module_id = []
            Object.keys(formData).forEach(key => {
                if (formData[key] === true) {
                    module_id.push(key);
                }
            })
            finalFormData.append("module_id", module_id);
            finalFormData.append("subscription_id", formData.subscription_id);
            this.props.action.updateSubscriptionModules(finalFormData).then((message) => {
                this.handleModuleCancel();
                notification.success({
                    message: Strings.success_title,
                    description: message,
                    onClick: () => { },
                    className: 'ant-success'
                });
            }).catch((error) => {
                if (error.status === VALIDATE_STATUS) {
                    notification.warning({
                        message: Strings.validate_title,
                        description: error && error.data && typeof error.data.message === 'string'
                            ? error.data.message : Strings.generic_validate,
                        onClick: () => { },
                        className: 'ant-warning'
                    });
                } else {
                    notification.error({
                        message: Strings.error_title,
                        description: error && error.data && error.data.message && typeof error.data.message === 'string'
                            ? error.data.message : Strings.generic_error,
                        onClick: () => { },
                        className: 'ant-error'
                    });
                }
            });
        }
    }

    handleEditClick = () => {
        this.setState({ displayEdit: 'block' });
        this.setState({ displayModulesEdit: 'none' });
        if (!this.state.cardExpnadBtn) {
            this.handleExpand()
        }
    }

    handleModuleEditClick = () => {
        this.setState({ displayEdit: 'none' });
        this.setState({ displayModulesEdit: 'block' });
        if (!this.state.cardExpnadBtn) {
            this.handleExpand()
        }
    }

    handleCancel = () => {
        this.setState({ displayEdit: 'none' });
    }
    handleModuleCancel = () => {
        this.setState({ displayModulesEdit: 'none' });
    }

    // expand center card----------
    handleExpand = () => {
        this.setState({ cardExpnadBtn: !this.state.cardExpnadBtn })
        $(".main-container").toggleClass("po-relative");
        $(".sf-card-wrap").toggleClass("fullscreen");
        $(".zoom-save-bnt").toggleClass("show-bnt");
    }

    render() {
        const { handleSubmit } = this.props;
        let subscript = this.props.subscriptions.find(subscript => subscript.subscription_id === this.props.location.state)
        const periodOptions = [{ title: 'Monthly', value: '1' }, { title: 'Quarterly', value: '3' }, { title: 'Yearly', value: '12' }]
        let menu = (<Menu>
            <Menu.Item onClick={this.handleEditClick}>
                Edit Subscription
            </Menu.Item>
        </Menu>);

        let subscriptionMenu = (<Menu>
            <Menu.Item onClick={this.handleModuleEditClick}>
                {"Edit Subscription Modules"}
            </Menu.Item>
        </Menu>);

        return (
            <div className={this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"}>
                <div className="row">
                    <div className="col-lg-8 col-md-12 mb-4">
                        <div className="sf-card-wrap">
                            {/* zoom button  */}
                            <div className="card-expands">
                                <button type="button" onClick={this.handleExpand} className="exapnd-bnt normal-bnt">
                                    <Icon type={this.state.cardExpnadBtn ? "fullscreen" : "fullscreen-exit"} /></button>
                            </div>
                            <div className="sf-card">
                                <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                    <h2 className="sf-pg-heading">{Strings.sub_details_title}</h2>
                                    <div className="info-btn">
                                        {/* Drop down for card */}
                                        <Dropdown className="more-info" overlay={menu}>
                                            <i className="material-icons">more_vert</i>
                                        </Dropdown>
                                        {/*Drop down*/}
                                    </div>
                                </div>

                                <div className="sf-card-body mt-2">
                                    <div className="data-v-row">
                                        <div className="data-v-col">
                                            <div className="view-text-value pr-3">
                                                <label>{Strings.sub_f_name}</label>
                                                <span>{subscript ? subscript.name : ''}</span>
                                            </div>
                                        </div>
                                        <div className="data-v-col">
                                            <div className="view-text-value pr-3">
                                                <label>{Strings.sub_f_amount}</label>
                                                <span>{subscript ? currencyFormat(subscript.amount) : ''}</span>
                                            </div>
                                        </div>
                                        <div className="data-v-col">
                                            <div className="view-text-value pr-3">
                                                <label>{Strings.sub_f_period}</label>
                                                <span>{subscript && subscript.period ? subscript.period === 1 ? "Monthly" : subscript.period === 3 ? "Quarterly" : subscript.period === 12 ? "Yearly" : '' : ''}</span>
                                            </div>
                                        </div>
                                        <div className="data-v-col">
                                            <div className="view-text-value pr-3">
                                                <label>{Strings.subscript_visibility}</label>
                                                <span>{subscript ? Boolean(subscript.org_visibility).toString() : ''}</span>
                                            </div>
                                        </div>
                                        <div className="data-v-col">
                                            <div className="view-text-value pr-3">
                                                <label>{Strings.sub_f_status}</label>
                                                <span>{subscript ? Boolean(subscript.active).toString() : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Subscription Modules Details */}

                            <div className="sf-card mt-4">
                                <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                    <h2 className="sf-pg-heading">{"Subscription Modules Details"}</h2>
                                    <div className="info-btn">
                                        {/* Drop down for card */}
                                        <Dropdown className="more-info" overlay={subscriptionMenu}>
                                            <i className="material-icons">more_vert</i>
                                        </Dropdown>
                                        {/*Drop down*/}
                                    </div>
                                </div>

                                <div className="sf-card-body mt-2">
                                    <ul className="sub-modl-items">
                                        {subscript && subscript.subscription_modules !== null | undefined ? subscript.subscription_modules.map((moduleName,index) => {
                                            return <li key={index} className="sub-items"><span>
                                                {moduleName && moduleName.module ? moduleName.module.module_name : ''}
                                            </span></li>
                                        }) : ''}
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Edit */}
                    <div className="col-md-12 col-lg-4" style={{ display: this.state.displayEdit }}>
                        <div className="sf-card">
                            <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                <h4 className="sf-sm-hd sf-pg-heading">{Strings.sub_edit_dtl_title}</h4>
                                <button className="closed-btn"><Icon type="close" /></button>
                            </div>
                            <div className="sf-card-body mt-2">
                                <form onSubmit={handleSubmit(this.onSubmit)} >
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.sub_f_name}
                                            name="name"
                                            type="text"
                                            id="name"
                                            component={customInput} />
                                    </fieldset>
                                    <fieldset className="form-group sf-form w-currency-symbl">
                                        <Field
                                            label={Strings.sub_f_amount}
                                            name="amount"
                                            type="text"
                                            id="amount"
                                            component={customInput} />
                                    </fieldset>
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label="Subscription Period"
                                            name="period"
                                            options={periodOptions}
                                            component={CustomSelect} />
                                    </fieldset>
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            name="active"
                                            id="active"
                                            label={Strings.subscript_status}
                                            component={CustomSwitch} />
                                        <Field
                                            name="org_visibility"
                                            label={Strings.subscript_visibility}
                                            component={CustomSwitch} />
                                    </fieldset>

                                    <div className="all-btn multibnt">
                                        <div className="btn-hs-icon d-flex justify-content-between">
                                            <button onClick={this.handleCancel} type="button" className="bnt bnt-normal" disabled={!this.props.isDirty}>
                                                {Strings.cancel_btn}</button>
                                            <button type="submit" className="bnt bnt-active" disabled={!this.props.isDirty}>
                                                {Strings.update_btn}</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>



                    {/* Update Subscription Modules */}
                    <div className="col-lg-4 col-md-12" style={{ display: this.state.displayModulesEdit }}>
                        <div className="sf-card">
                            <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                <h4 className="sf-sm-hd sf-pg-heading">{"Edit Subscription Modules"}</h4>
                                <button className="closed-btn" onClick={this.handleModuleCancel}><Icon type="close" /></button>
                            </div>

                            <div className="sf-card-body">

                                <form onSubmit={handleSubmit(this.onSubmit)} >
                                    <div className="user-mng-chkbx">
                                        <div className="sf-checkbox-b">
                                            <div className="sf-roles-group add-sub-mod">
                                                {this.props.modules && this.props.modules.length ? this.props.modules.map((modulesName,index) => {
                                                    return <Field key={index}
                                                        name={modulesName ? modulesName.id.toString() : ''}
                                                        label={modulesName && modulesName.module_name ? modulesName.module_name : ''} component={CustomCheckbox} />
                                                }) : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="all-btn multibnt">
                                        <div className="btn-hs-icon d-flex justify-content-between">
                                            <button onClick={this.handleModuleCancel} className="bnt bnt-normal" type="button" disabled={!this.props.isDirty}>
                                                {Strings.cancel_btn}</button>
                                            <button type="submit" className="bnt bnt-active" disabled={!this.props.isDirty}>
                                                {Strings.update_btn}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let value = state.subscription.subscriptions.find(subscript => subscript.subscription_id === ownProps.location.state);

    if (value && value.subscription_modules !== null | undefined) {
        value.subscription_modules.map(modules => {
            if (modules.module_id && value !== undefined) {
                value[modules.module_id] = true;
            }

            return value;
        })
    }
    return {
        subscriptions: state.subscription.subscriptions,
        modules: state.moduleManagement.modules,
        initialValues: value,
        isDirty: isDirty('viewEditSubscription')(state),

    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
        modulesAction: bindActionCreators(modulesAction, dispatch)
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: 'viewEditSubscription', validate, enableReinitialize: true, onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(ViewEditSubscription)
