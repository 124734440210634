import React from 'react';
import { connect } from 'react-redux';
import { Icon, Dropdown, notification } from 'antd';
import {
    reduxForm,
    Field
} from 'redux-form';
import { compose } from 'redux';
import $ from 'jquery';

import { validate } from '../../../utils/Validations/accessControlValidation';
import { customInput } from '../../common/custom-input';
import * as actions from '../../../actions/accessControlManagementAction';
import { CustomSwitch } from '../../common/customSwitch'
import { Strings } from '../../../dataProvider/localize';
import { CustomSelect } from '../../common/customSelect';
import { handleFocus, DeepTrim, accessType } from '../../../utils/common';

class AddNewAccessControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cardExpnadBtn: true }
    }

    onSubmit = async (formData) => {
        formData = await DeepTrim(formData);

        formData.active = +formData.active;
        this.props.addAccessControl(formData).then((message) => {
            notification.success({
                message: Strings.success_title,
                description: message ? message : "",
                onClick: () => {},
                className:'ant-success'
            });
            this.props.reset();
        }).catch((message) => {
            notification.error({
                message: Strings.error_title,
                description: message ? message : Strings.generic_error,
                onClick: () => {},
                className:'ant-error'
            });
        });
    }

    // expand center card----------
    handleExpand = () => {
        this.setState({ cardExpnadBtn: !this.state.cardExpnadBtn })
        $(".main-container").toggleClass("po-relative");
        $(".sf-card-wrap").toggleClass("fullscreen");
        $(".zoom-save-bnt").toggleClass("show-bnt");
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"}>
                <div className="row">
                    <div className="col-md-12 col-lg-8">
                        <form onSubmit={handleSubmit(this.onSubmit)}>
                            <div className="sf-card-wrap">
                                {/* zoom button  */}
                                <div className="card-expands">
                                    <button type="button" onClick={this.handleExpand} className="exapnd-bnt normal-bnt">
                                        <Icon type={this.state.cardExpnadBtn ? "fullscreen" : "fullscreen-exit"} /></button>
                                </div>
                                <div className="sf-card">
                                    <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                        <h2 className="sf-pg-heading">Access Control Details</h2>
                                        <div className="info-btn disable-dot-menu">
                                            <Dropdown className="more-info" disabled overlay={''}>
                                                <i className="material-icons">more_vert</i>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="sf-card-body mt-2">
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.module_name}
                                                name="module_id"
                                                placeholder={Strings.module_id_acm}
                                                type="text"
                                                id="module_id"
                                                options={this.props.modules.map(moduleName => ({ title: moduleName.module_name, value: moduleName.id }))}
                                                component={CustomSelect} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.access_control_title_name}
                                                name="title"
                                                placeholder={Strings.title_acm}
                                                type="text"
                                                id="title"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.access_control_controller_name}
                                                name="controller_name"
                                                placeholder={Strings.controller_name_acm}
                                                type="text"
                                                id="controller_name"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.access_control_function_name}
                                                name="function_name"
                                                placeholder={Strings.function_name_acm}
                                                type="text"
                                                id="function_name"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.order}
                                                name="sort_order"
                                                placeholder={Strings.sort_order_acm}
                                                type="number"
                                                id="sort_order"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={"Access Type"}
                                                name="access_type"
                                                placeholder={"Select Access Type"}
                                                type="text"
                                                id="module_id"
                                                options={accessType}
                                                component={CustomSelect} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={"Description"}
                                                name="description"
                                                placeholder={"Description"}
                                                type="text"
                                                id="title"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={"Tool Tip"}
                                                name="tooltip"
                                                placeholder={"Tool tip text"}
                                                type="text"
                                                id="title"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                name="active"
                                                id="active"
                                                label={Strings.access_control_status}
                                                component={CustomSwitch} />
                                        </fieldset>

                                    </div>
                                </div>
                                {/* zoom save button  */}
                                <div className="row zoom-save-bnt">
                                    <div className="col-md-12">
                                        <div className="all-btn d-flex justify-content-end mt-4">
                                            <div className="btn-hs-icon">
                                                <button type="submit" className="bnt bnt-active">
                                                    <i className="material-icons">save</i> {Strings.save_btn}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="all-btn d-flex justify-content-end mt-4">
                                <div className="btn-hs-icon">
                                    <button type="submit" className="bnt bnt-active">
                                        <i className="material-icons">save</i> {Strings.save_btn}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        accessControls: state.accessControlManagement.accessControls,
        modules: state.moduleManagement.modules,
        initialValues: {
            title: '',
            active: true,
            module_id: [],
            access_type:[]
        }
    }
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ form: 'addNewAccessControl', validate: validate , onSubmitFail: (errors, dispatch, sub, props) => {
        handleFocus(errors, "#");
      }})
)(AddNewAccessControl)
