import { 
    GET_LIKELIHOOD_AFTER_CONTROL_GET
} from '../dataProvider/constant';

const DEFAULT_STATE = {
    likelihoodAfterControls: [],
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case GET_LIKELIHOOD_AFTER_CONTROL_GET:
            return { ...state, likelihoodAfterControls: action.payload };
        default:
            return state;
    }
}