import {
  ORGANIZATION_GET_ORGANIZATION,
  ORGANIZATION_URL, ORG_SUBSCRIPTION,
  INIT_ORGANIZATION_LIST,
  GET_ORGANIZATION_BY_EXPAND,
  GET_ORGANIZATION_BY_SEARCH,
  UPDATE_SA_INDUS_URL, DELETE_SA_SERVICE, DELETE_SA_INDUS
} from '../dataProvider/constant'
import {
  startSipnner, stopSipnner,
  startMiniSpinner, stopMiniSpinner,
  startScrollSpinner, stopScrollSpinner
} from '../utils/spinner';
import axiosInstance from '../dataProvider/axiosHelper';
import { Strings } from '../dataProvider/localize'
import {
  BASE_API_URL
} from '../dataProvider/env.config'

const getAllOrganizationApi = dispatch => {

  return axiosInstance.get(ORGANIZATION_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: ORGANIZATION_GET_ORGANIZATION,
          payload: res.data.data.organisations
        })
        return Promise.resolve(true);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const getOrganization = () => dispatch => {
  startSipnner(dispatch);
  return getAllOrganizationApi(dispatch);
}

export const addOrganization = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(BASE_API_URL + ORGANIZATION_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(res => {
      if (res.data.status) {
        initAllOrganisationsApi(dispatch)
        return Promise.resolve(res.data);
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error);
    });
}

export const updateOrganization = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(BASE_API_URL + ORGANIZATION_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(res => {
      if (res.data.status) {
        getAllOrganizationApi(dispatch)
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateSubscriptionLevel = (formData, org_id) => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(ORG_SUBSCRIPTION, formData)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        getAllOrganizationApi(dispatch);
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const initOrganisations = () => dispatch => {
  return initAllOrganisationsApi(dispatch)
}

const initAllOrganisationsApi = dispatch => {
  startSipnner(dispatch)

  const searchKey = '';
  const pageNumber = 1;

  const url = `${ORGANIZATION_URL}?search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INIT_ORGANIZATION_LIST,
          payload: res.data.data.organisations
        })
        return Promise.resolve()
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const searchExpandOrganisationsList = (searchKey = '', pageNumber = 1, searching, scrolling) => dispatch => {

  searching ? startMiniSpinner(dispatch) : scrolling ? startScrollSpinner(dispatch) : startSipnner(dispatch)

  const url = `${ORGANIZATION_URL}?search_key=${searchKey}&page=${pageNumber}`
  return axiosInstance.get(url)
    .then(res => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      if (res.data.status && !searching) {
        dispatch({
          type: GET_ORGANIZATION_BY_EXPAND,
          payload: res.data.data.organisations,
        })
        return Promise.resolve(res.data.data.organisations.length)
      } else {
        dispatch({
          type: GET_ORGANIZATION_BY_SEARCH,
          payload: res.data.data.organisations,
        })
        return Promise.resolve(res.data.data.organisations.length)
      }
    })
    .catch(error => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}


export const updateServiceAgentService = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(UPDATE_SA_INDUS_URL, formData)
    .then(async res => {
      if (res.data.status) {
        await getAllOrganizationApi(dispatch)
        stopSipnner(dispatch)
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response : Strings.network_error);
    });
}

export const deleteSAService = (data) => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.delete(DELETE_SA_SERVICE, { data: data })
    .then(res => {
      if (res.data.status) {
        getAllOrganizationApi(dispatch)
        stopSipnner(dispatch);
        return Promise.resolve(res.data)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const deleteSAIndustry = (data) => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.delete(DELETE_SA_INDUS, { data: data })
    .then(res => {
      if (res.data.status) {
        getAllOrganizationApi(dispatch)
        stopSipnner(dispatch);
        return Promise.resolve(res.data)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}