import React from 'react';
import { Icon, Progress, notification } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions/organizationAction';
import { Route } from 'react-router-dom';
import OrganizationSearch from './OrganizationSearch';
import AddOrganization from './AddOrganization';
import ViewEditOrganization from './ViewEditOrganization';
import { Strings } from '../../../dataProvider/localize';
import { goBack } from '../../../utils/common';
import { ERROR_NOTIFICATION_KEY } from '../../../config';
const mapRouteToTitle = {
    '/dashboard/organization/createOrganization': Strings.add_org_title
}

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptLevelSection: false,
      orgDetailsSection: false,
      orgUserSection: false,
      formCompletion: 0,
      togleSearch: true,
      firstLoadDone: false
    }

        // this.props.action.initOrganisations()
        //   .then((flag) => {
        //     this.createOrganizationnHandler();
        //   })
        //   .catch((message) => {
        //     notification.error({
        //       key: ERROR_NOTIFICATION_KEY,
        //       message: Strings.error_title,
        //       description: message ? message : Strings.generic_error,
        //       onClick: () => { },
        //       className: 'ant-error'
        //     });
        //   });
    }

  componentDidMount() {
    this.props.action.initOrganisations()
      .then((flag) => {
        if (this.props.location.state && this.props.location.state.fromLink)
        this.createOrganizationnHandler();
      })
      .catch((message) => {
        notification.error({
          key: ERROR_NOTIFICATION_KEY,
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: 'ant-error'
        });
      });
  }

    createOrganizationnHandler = () => {
        this.props.history.push(this.props.match.path + '/createOrganization')
    }

    handleSearchToggle = () => {
        this.setState({ togleSearch: !this.state.togleSearch })
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.location.pathname.includes("/createOrganization")) {
            this.setState({
              orgDetailsSection: false,
              subscriptLevelSection: false,
              orgUserSection: false,
              formCompletion: 0
            })
            let values = {}
            if (props.formValues && props.formValues.values) {
                values = props.formValues.values;
            }
            if (values.name && values.email_address && values.phone_number && values.primary_person) {
              this.setState({
                orgDetailsSection: true
              })
            }
            if (values.subscription_id) {
                this.setState({
                  subscriptLevelSection: true
                })
            }
            if (values.org_users && values.org_users.length > 0 && Object.keys(values.org_users[0]).length === 5) {
                this.setState({
                  orgUserSection: true
                })
            }
            if (values.billing_email_address && values.billing_address) {
                this.setState({
                  billingSection: true
                })
            }
            let percentageValues = Object.keys(values)
                .filter(key => (key === 'name' || key === 'email_address' || key === 'phone_number' || key === 'primary_person')
                    || (key === 'subscription_id') || (key === 'billing_address') || (key === 'billing_email_address') || (key === 'abn_acn')
                    || (key === 'org_users' && (values.org_users[0] && Object.keys(values.org_users[0]).length === 5))
                );
            this.setState({
              formCompletion: Math.round((percentageValues.length / 9) * 100)
            })
        } else if (props.location.pathname.includes("/showOrganization")) {
            this.setState({
              orgDetailsSection: true,
              subscriptLevelSection: true,
              orgUserSection: true,
              formCompletion: 78,
              billingSection: false,
            })
            let values = {}
            if (props.billingFormValues && props.billingFormValues.values) {
                values = props.billingFormValues.values
            }
            if (props.billingDetails.billing_email_address && props.billingDetails.billing_address) {
                this.setState({
                  formCompletion: 100,
                  billingSection: true
                })
            } else {
                if (values.billing_email_address && values.billing_address)
                this.setState({
                  billingSection: true
                })
                let percentage = 0;
                percentage = (Object.keys(values)
                    .filter(key => ((key === 'billing_email_address' && values[key]) || (key === 'billing_address' && values[key]))).length) * 11;
                this.setState(prevState => ({
                  formCompletion: prevState.formCompletion + percentage
                }));
            }
        }
    }


    render() {

        return (
            <div>
                {/* inner header  */}
                <div className="dash-header">
                    <h2 className="page-mn-hd">
                        <Icon type="arrow-left" onClick={() => goBack(this.props/* this.props.history.push({ pathname: '/dashboard/userManagement', state: this.props.location.state }) */)} />
                        {mapRouteToTitle[this.props.location.pathname] ? mapRouteToTitle[this.props.location.pathname] : Strings.org_title}
                    </h2>
                    <div className={"sf-steps-status"} >
                        <div className="sf-steps-row">
                            <div className={this.state.orgDetailsSection ? "sf-st-item active" : "sf-st-item"}>
                                <div className="iconbx">
                                    <i className="material-icons">assignment</i>
                                </div>
                                <span>{Strings.org_wizard_detail}</span>
                            </div>
                            <div className={this.state.billingSection ? "sf-st-item active" : "sf-st-item"}>
                                <div className="iconbx">
                                    <i className="material-icons">attach_money</i>
                                </div>
                                <span>{"Billing"}</span>
                            </div>
                            <div className={this.state.orgUserSection ? "sf-st-item active" : "sf-st-item"}>
                                <div className="iconbx">
                                    <i className="material-icons">group</i>
                                </div>
                                <span>{Strings.org_wizard_user}</span>
                            </div>
                            <div className={this.state.subscriptLevelSection ? "sf-st-item active" : "sf-st-item"}>
                                <div className="iconbx">
                                    <i className="material-icons">subscriptions</i>
                                </div>
                                <span>{Strings.org_wizard_sub}</span>
                            </div>
                        </div>
                        <div className="sf-st-item sf-prog">
                            <Progress type="circle" strokeColor={'#03a791'} width={40} strokeWidth={12} percent={this.state.formCompletion} format={
                                (percent) => percent + '%'} />
                            <span>{Strings.org_wizard_progress}</span>
                        </div>
                    </div>
                    <div className="oh-cont">
                        <button className="bnt bnt-active" onClick={this.createOrganizationnHandler}>{Strings.org_add_btn}</button>
                    </div>
                </div>
                {/* inner header  */}
                <div className="main-container">
                    <div className="row">
                        {/* Left section */}
                        <OrganizationSearch handleSearchToggle={this.handleSearchToggle} togleSearch={this.state.togleSearch} />
                        {/* center section  */}
                        <Route
                            path={this.props.match.path + '/showOrganization'}
                            render={(props) => <ViewEditOrganization {...props} togleSearch={this.state.togleSearch} />} />
                        <Route
                            path={this.props.match.path + '/createOrganization'}
                            render={(props) => <AddOrganization {...props} togleSearch={this.state.togleSearch} />} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        organizations: state.organization.organizations,
        formValues: state.form.addOrganization,
        billingFormValues: state.form.editBillingAddress,
        billingDetails: state.organizationBilling.billingDetails,
    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(Organization)
