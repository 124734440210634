import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import SignUp from '../auth/signup';
import SignIn from '../auth/signin';
import Forgotpass from '../auth/forgotpass';
import Reseturpass from '../auth/reseturpass';
import Forgotusername from '../auth/forgotusername';
import Dashboard from '../dashboard/dashboard';
import SignInCode from '../auth/login-sms-code-form';
import { createBrowserHistory } from 'history';
export const browserHistory = createBrowserHistory();
class HeadRoute extends React.Component {

	render() {
		return (

			<BrowserRouter>
				<Switch>
					<Route path='/dashboard' history={browserHistory} component={Dashboard} />
					<Route exact path='/' component={() => <Redirect to="/signin" />} />
					<Route exact path='/signup' component={SignUp} />
					<Route exact path='/signin' component={SignIn} />
					<Route exact path='/forgotpass' component={Forgotpass} />
					<Route exact path='/reseturpass' component={Reseturpass} />
					<Route exact path='/forgotusername' component={Forgotusername} />
					<Route exact path='/signin_code' component={SignInCode} />
				</Switch>
			</BrowserRouter>
		)
	}
}



export default HeadRoute;
