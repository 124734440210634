import React from 'react';
import { Icon, notification } from 'antd';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import * as actions from '../../../actions/moduleManagementAction';
import { bindActionCreators } from 'redux';

import ModuleSearch from './ModuleSearch';
import AddNewModule from './AddNewModule';
import EditModule from './EditModule';
import { Strings } from '../../../dataProvider/localize';
import { goBack } from '../../../utils/common';
import { ERROR_NOTIFICATION_KEY } from '../../../config';
const mapRouteToTitle = {
  '/dashboard/modulesManagement/createModule': Strings.add_module_title
}

class ModuleManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { togleSearch: true }
  }

  componentDidMount() {
    this.props.action.initModules()
      .then((flag) => {
        if (this.props.location.state && this.props.location.state.fromLink)
        this.createModuleHandler()
      })
      .catch((message) => {
        notification.error({
          key: ERROR_NOTIFICATION_KEY,
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: 'ant-error'
        });
      });
  }

  createModuleHandler = () => {
    this.props.history.push(this.props.match.path + '/createModule')
  }
  handleSearchToggle = () => {
    this.setState({ togleSearch: !this.state.togleSearch })
  }

  render() {

    return (
      <div className="sf-page-layout">
        {/* inner header  */}
        <div className="dash-header">
          <h2 className="page-mn-hd">
            <Icon type="arrow-left" onClick={() => goBack(this.props)} />
            {mapRouteToTitle[this.props.location.pathname] ? mapRouteToTitle[this.props.location.pathname] : Strings.module_title}
          </h2>

          <div className="oh-cont">
            <button className="bnt bnt-active" onClick={this.createModuleHandler}>{Strings.add_module_btn}</button>
          </div>
        </div>
        {/* inner header  */}
        <div className="main-container">
          <div className="row">
            {/* Left section */}
            <ModuleSearch handleSearchToggle={this.handleSearchToggle} togleSearch={this.state.togleSearch} />
            {/* center section  */}
            <Route path={this.props.match.path + '/showModule'}
              render={(props) => <EditModule {...props} togleSearch={this.state.togleSearch} />} />
            <Route path={this.props.match.path + '/createModule'}
              render={(props) => <AddNewModule {...props} togleSearch={this.state.togleSearch} />} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modules: state.moduleManagement.modules,
  }
}

const mapDispatchToprops = dispatch => {
  return {
    action: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToprops)(ModuleManagement)
