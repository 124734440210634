import React from 'react';
import { Icon, notification } from 'antd';
import { reduxForm, Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../common/custom-input';
import { Strings } from '../../../dataProvider/localize';
import { validate } from '../../../utils/Validations/subscriptionLevelValidate';
import * as organizationAction from "../../../actions/organizationAction";
import { CustomSelect } from '../../common/customSelect';
import { CustomDatepicker } from '../../common/customDatepicker';
import moment from 'moment';
import { currencyFormat, handleFocus, DeepTrim } from '../../../utils/common';
import {VALIDATE_STATUS} from '../../../dataProvider/constant'

class EditSubscriptionLevel extends React.Component {

    constructor(props) {
        super(props)
        this.sub_amount = 0
    }

    componentDidMount() {
        this.sub_amount = this.props.organization && this.props.organization.sub_amount;
    }

    onSubmit = async (formData) => {
        formData = await DeepTrim(formData);

        const { organization } = this.props
        formData.id = organization.id
        formData.sub_amount = this.sub_amount
        console.log(formData)
        this.props.organizationAction.updateSubscriptionLevel(formData, organization.id)
            .then((message) => {
                this.props.handleCancel()
                notification.success({
                    message: Strings.success_title,
                    description: message,
                    onClick: () => { },
                    className: 'ant-success'
                })
            }).catch((error) => {
                if (error.status === VALIDATE_STATUS) {
                  notification.warning({
                    message: Strings.validate_title,
                    description: error && error.data && typeof error.data.message === 'string'
                      ? error.data.message : Strings.generic_validate,
                    onClick: () => { },
                    className: 'ant-warning'
                  });
                } else {
                  notification.error({
                    message: Strings.error_title,
                    description: error && error.data && error.data.message && typeof error.data.message === 'string'
                      ? error.data.message : Strings.generic_error,
                    onClick: () => { },
                    className: 'ant-error'
                  });
                }
              });
    }

    calculateSubscriptionAmt = () => {
        console.log(this.props)
        if (this.props.formValues && this.props.formValues.subscription_id && this.props.formValues.sub_period && this.props.formValues.sub_period) {
            let subscription = this.props.subscriptions.find(
                subscript => subscript.subscription_id === this.props.formValues.subscription_id);
            if (subscription) {
                console.log(subscription.amount);
                return subscription.amount - (((subscription.amount / subscription.period * this.props.formValues.sub_period)
                    * this.props.formValues.sub_discount) / 100).toFixed(2)
            }
        }
        if (this.props.formValues && this.props.formValues.subscription_id) {
            let subscription = this.props.subscriptions.find(
                subscript => subscript.subscription_id === this.props.formValues.subscription_id);
            if (subscription) {
                return subscription.amount
            }
        }
    }

    handleCancel = () => {
        this.props.reset()
        this.props.handleCancel()
    }

    render() {
        const { handleSubmit, showSubscriptionLevel } = this.props;
        this.sub_amount = this.calculateSubscriptionAmt()

        return (
            <div className="sf-card mb-4" style={{ display: showSubscriptionLevel }}>
                <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                    <h4 className="sf-sm-hd sf-pg-heading">Update Subscription Level</h4>
                    <button className="closed-btn" onClick={this.handleCancel}><Icon type="close" /></button>
                </div>
                <div className="sf-card-body mt-2">
                    <form onSubmit={handleSubmit(this.onSubmit)}>
                        <fieldset className="sf-form form-group">
                            <Field name='subscription_id' label={Strings.sub_type} type="text"
                                options={
                                    this.props.subscriptions
                                        .map(subscript => ({
                                            title: subscript.name, value: subscript.subscription_id,
                                            disabled: !subscript.active
                                        }))} component={CustomSelect}
                                placeholder="Select user" />
                        </fieldset>

                        <fieldset className="form-group sf-form lsico">
                            <Field
                                label={Strings.sub_invoice_date}
                                name="invoice_start_date"
                                placeholder={moment(new Date()).format('YYYY-MM-DD')}
                                component={CustomDatepicker} />
                        </fieldset>
                        <fieldset className="form-group sf-form">
                            <Field
                                label={Strings.sub_percentage}
                                name="sub_discount"
                                placeholder={Strings.sub_discount}
                                type="text"
                                id=""
                                component={customInput} />
                        </fieldset>
                        <fieldset className="form-group sf-form">
                            <Field
                                label={Strings.sub_discount_period}
                                placeholder={Strings.sub_period}
                                name="sub_period"
                                type="text"
                                id=""
                                component={customInput} />
                        </fieldset>
                        <div className="view-text-value mb-3">
                            <label>{Strings.sub_discount_amt}</label>
                            <span>{currencyFormat(this.sub_amount)}</span>
                        </div>
                        <div className="view-text-value mb-2">
                            <label>{Strings.sub_date}</label>
                            <span>{moment().format('DD MMM YYYY')}</span>
                        </div>
                        {/* <fieldset className="form-group sf-form">
                            <Field
                                name="active"
                                id="active"
                                label={Strings.access_control_status}
                                component={CustomSwitch} />
                        </fieldset> */}

                        <div className="all-btn multibnt">
                            <div className="btn-hs-icon d-flex justify-content-between">
                                <button onClick={this.handleCancel} className="bnt bnt-normal" type="button" disabled={!this.props.isDirty}>
                                    {Strings.cancel_btn}</button>
                                <button type="submit" className="bnt bnt-active" disabled={!this.props.isDirty}>
                                    {Strings.update_btn}</button>
                            </div>
                        </div>

                    </form>
                </div>
                {/**Subscription Details */}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let values = ownProps.organization
    if (values) {
        values.active = ownProps.organization && ownProps.organization.subscription_id && state.subscription.subscriptions.find(sub => sub.subscription_id === ownProps.organization.subscription_id)
            && state.subscription.subscriptions.find(sub => sub.subscription_id === ownProps.organization.subscription_id).active
    }

    return {
        subscriptions: state.subscription.subscriptions,
        initialValues: ownProps.organization,
        formValues: state.form.EditSubscriptionLevel && state.form.EditSubscriptionLevel.values ? state.form.EditSubscriptionLevel.values : {},
        isDirty: isDirty('EditSubscriptionLevel')(state),
    }
}


const mapDispatchToprops = dispatch => {
    return {
        organizationAction: bindActionCreators(organizationAction, dispatch),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({ form: 'EditSubscriptionLevel', validate: validate, enableReinitialize: true , onSubmitFail: (errors, dispatch, sub, props) => {
        handleFocus(errors, "#");
      }})
)(EditSubscriptionLevel)
