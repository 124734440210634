import {
  JWT_ID_TOKEN,
  JWT_ACCESS_TOKEN,
  JWT_REFRESH_TOKEN,
  USER_NAME
} from "../dataProvider/constant";
import $ from "jquery";
import { browserHistory } from "../components/Routes/headRoute";

export const goBackBrowser = (props, newPath) => {
  let pathname = props.history.location.pathname;
  if (
    pathname === "signin" ||
    pathname === "signin_code" ||
    pathname === "signup"
  ) {
    return;
  } else {
    browserHistory.goBack();
    /* var unlisten = browserHistory.listen(({ pathname, action }) => {
      // console.log('PREPARING REDIRECT TO ' + pathname,action)
      if (props.location.pathname.split("/")[2] === pathname.split("/")[2] && newPath !== pathname)
        goBack(props, pathname)
      else
        unlisten()
    }) */
  }
};

export const goBack = (props, newPath) => {
  let pathname = props.history.location.pathname;
  if (
    pathname === "signin" ||
    pathname === "signin_code" ||
    pathname === "signup"
  ) {
    return;
  } else {
    window.location.replace("/dashboard/dashboard");
  }
};

export const abbrivationStr = text => {
  let textArray = text.split(" ", 2);
  if (textArray.length > 1) {
    let abbr = textArray[0].charAt(0) + textArray[1].charAt(0);
    return abbr.toUpperCase();
  } else {
    let abbr = textArray[0].charAt(0) + textArray[0].charAt(1);
    return abbr.toUpperCase();
  }
};

export const getStorage = key => {
  return localStorage.getItem(key);
};

export const setStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const authRedirect = history => {
  if (
    getStorage(JWT_ID_TOKEN) &&
    getStorage(JWT_ACCESS_TOKEN) &&
    getStorage(JWT_REFRESH_TOKEN) &&
    getStorage(USER_NAME)
  ) {
    history.push("/dashboard");
  }
};

export function DeepTrim(obj) {
  for (var prop in obj) {
    var value = obj[prop],
      type = typeof value;
    if (
      value !== null &&
      (type === "string" || type === "object") &&
      obj.hasOwnProperty(prop)
    ) {
      if (type === "object") {
        obj[prop] = DeepTrim(obj[prop]);
      } else {
        obj[prop] = obj[prop].trim();
      }
    }
  }
  return obj;
}
/* 
DCI-723 comments
Allow ph no +61nxxxxxxxx or 0nxxxxxxxx where n can be 2, 3, 4, 7, and 
This is 12 chars including +, with the 4th char in [2, 3, 4, 7, 8] 
or 10 chars with the 2nd char in [2, 3, 4, 7, 8].
*/
export const customPhonoeNoValidate = phone_number => {
  var substr = "";
  if (phone_number.startsWith("+61") && phone_number.length === 12) {
    substr = phone_number.substring(3, 4);
  }

  if (phone_number.startsWith("0") && phone_number.length === 10) {
    substr = phone_number.substring(1, 2);
  }

  if (substr === 2 || substr === 3 || substr === 4 || substr === 7 || substr === 8) {
    return true; //allow phone no
  } else {
    return false; //show error
  }
};

export function currencyFormat(value) {
  var formatedCurrency = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "aud"
  }).format(value);
  return formatedCurrency;
}

export const handleFocus = (errors, str) => {
  console.log(errors);
  for (let key in errors) {
    if (typeof errors[key] === "string") {
      $(str + key + "-focus").focus();
      return str + key;
    } else if (Array.isArray(errors[key]) && errors[key].length > 0) {
      for (let i = 0; i < errors[key].length; i++) {
        if (errors[key][i])
          if (
            typeof handleFocus(
              errors[key][i],
              str + key + "\\[" + i + "\\]\\."
            ) === "string"
          )
            return handleFocus(
              errors[key][i],
              str + key + "\\[" + i + "\\]\\."
            );
      }
    } else if (
      typeof errors[key] === "object" &&
      Object.keys(errors[key]).length > 0
    ) {
      if (typeof handleFocus(errors[key], str + key + "\\.") === "string")
        return handleFocus(errors[key], str + key + "\\.");
    }
  }
};

export const accessType = [
  { title: "Add", value: "Add" },
  { title: "Edit", value: "Edit" },
  { title: "View", value: "View" },
  { title: "Restricted View", value: "Restricted View" },
  { title: "Delete", value: "Delete" }
];

export const calculateRisk = (likelihood_control, consequence_control) => {
  if (likelihood_control && consequence_control) {
    let key_before_control = "$" + likelihood_control.order + "_" + consequence_control.order
    let resultObj = {
      $1_1: "1. Low",
      $1_2: "2. Low",
      $1_3: "3. Low",
      $1_4: "4. Moderate",
      $1_5: "5. Moderate",

      $2_1: "2. Low",
      $2_2: "4. Moderate",
      $2_3: "6. Moderate",
      $2_4: "8. High",
      $2_5: "10. High",

      $3_1: "3. Low",
      $3_2: "6. Moderate",
      $3_3: "9. High",
      $3_4: "12. Very High",
      $3_5: "15. Very High",

      $4_1: "4. Moderate",
      $4_2: "8. high",
      $4_3: "12. Very High",
      $4_4: "16. Extreme",
      $4_5: "20. Extreme",

      $5_1: "5. Moderate",
      $5_2: "10. High",
      $5_3: "15. Very High",
      $5_4: "20. Extreme",
      $5_5: "25. Extreme"
    }
    return resultObj[key_before_control];

  }

  /*  let key_before_control = (likelihood_control_name && consequence_control_name) ? likelihood_control_name.replace(/ /g, "_") + "_" + consequence_control_name.replace(/ /g, "_") : null;
 
   let resultObj = {
     Almost_Certain_Insignificant: 'Medium',
     Almost_Certain_Minor: 'High',
     Almost_Certain_Moderate: 'High',
     Almost_Certain_Major: 'Extreme',
     Almost_Certain_Severe: 'Extreme',
 
     Likely_Insignificant: 'Medium',
     Likely_Minor: 'Medium',
     Likely_Moderate: 'High',
     Likely_Major: 'Extreme',
     Likely_Severe: 'Extreme',
 
     Possible_Insignificant: 'Medium',
     Possible_Minor: 'Medium',
     Possible_Moderate: 'High',
     Possible_Major: 'High',
     Possible_Severe: 'Extreme',
 
     Unlikely_Insignificant: 'Low',
     Unlikely_Minor: 'Medium',
     Unlikely_Moderate: 'Medium',
     Unlikely_Major: 'High',
     Unlikely_Severe: 'High',
 
     Rare_Insignificant: 'Low',
     Rare_Minor: 'Low',
     Rare_Moderate: 'Medium',
     Rare_Major: 'High',
     Rare_Severe: 'High',
   } */

}
