import {
  GET_ACCESS_CONTROL,
  GET_ACCESS_CONTROL_BY_MODULE,
  INIT_ACCESS_CONTROLS_LIST,
  GET_ACCESS_CONTROLS_BY_EXPAND,
  GET_ACCESS_CONTROLS_BY_SEARCH,
} from '../dataProvider/constant';

const DEFAULT_STATE = {
  accessControls: [],
  accessControlsByModule: []
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_ACCESS_CONTROL:
      console.log('GET_ACCESS_CONTROL')
      return {
        ...state,
        accessControls: action.payload
      };

    case INIT_ACCESS_CONTROLS_LIST:
      console.log('INIT_ACCESS_CONTROLS_LIST')
      state.accessControls = []
      return {
        ...state,
        accessControls: action.payload,
        currentPageNumber: 1
      }

    case GET_ACCESS_CONTROLS_BY_EXPAND:
      console.log('GET_ACCESS_CONTROLS_BY_EXPAND')
      const accessControls = [...state.accessControls, ...action.payload]
      const updatedList = accessControls.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
      });
      return {
        ...state,
        accessControls: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }

    case GET_ACCESS_CONTROLS_BY_SEARCH:
      console.log('GET_ACCESS_CONTROLS_BY_SEARCH')
      return {
        ...state,
        accessControls: action.payload,
        currentPageNumber: 1
      }

    case GET_ACCESS_CONTROL_BY_MODULE:
      return {
        ...state,
        accessControlsByModule: action.payload
      };
    default:
      return state;
  }
}