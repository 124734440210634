export const AUTH_SIGN_UP = "AUTH_SIGN_UP";
export const AUTH_SIGN_IN = "AUTH_SIGN_IN";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const START_SPINNER = "START_SPINNER";
export const STOP_SPINNER = "STOP_SPINNER";
export const STOP_MINI_SPINNER = 'STOP_MINI_SPINNER';
export const START_MINI_SPINNER = 'START_MINI_SPINNER';
export const START_SCROLL_SPINNER = 'START_SCROLL_SPINNER';
export const STOP_SCROLL_SPINNER = 'STOP_SCROLL_SPINNER';
export const AUTH_FORGOT_PASS = "AUTH_FORGOT_PASS";
export const AUTH_RESET = "AUTH_RESET";
export const AUTH_FORGOT_USER = "AUTH_FORGOT_USER_URL";
export const AUTH_CLEAN_STORE = "AUTH_CLEAN_STORE";
export const ROLE_GET_ROLES = "ROLE_GET_ROLES";

export const SUBSCRIPT_GET_SUBSCRIPTS = "SUBSCRIPT_GET_SUBSCRIPTS";
export const INIT_SUBSCRIPT_LIST = 'INIT_SUBSCRIPT_LIST';
export const GET_SUBSCRIPT_BY_EXPAND = 'GET_SUBSCRIPT_BY_EXPAND';
export const GET_SUBSCRIPT_BY_SEARCH = 'GET_SUBSCRIPT_BY_SEARCH';

export const ORGANIZATION_GET_ORGANIZATION = "ORGANIZATION_GET_ORGANIZATION";
export const INIT_ORGANIZATION_LIST = 'INIT_ORGANIZATION_LIST';
export const GET_ORGANIZATION_BY_EXPAND = 'GET_ORGANIZATION_BY_EXPAND';
export const GET_ORGANIZATION_BY_SEARCH = 'GET_ORGANIZATION_BY_SEARCH'
export const ORG_USERS = "ORG_USERS";
export const ORG_BILLING_DETAILS = "ORG_BILLING_DETAILS";
export const ORG_USERS_PERMISSION = "ORG_USERS_PERMISSION";

export const MODULE_GET_MODULES = 'MODULE_GET_MODULES';
export const INIT_MODULES_LIST = 'INIT_MODULES_LIST';
export const GET_MODULES_BY_EXPAND = 'GET_MODULES_BY_EXPAND';
export const GET_MODULES_BY_SEARCH = 'GET_MODULES_BY_SEARCH';

export const GET_ACCESS_CONTROL = 'GET_ACCESS_CONTROL';
export const INIT_ACCESS_CONTROLS_LIST = 'INIT_ACCESS_CONTROLS_LIST';
export const GET_ACCESS_CONTROLS_BY_EXPAND = 'GET_ACCESS_CONTROLS_BY_EXPAND';
export const GET_ACCESS_CONTROLS_BY_SEARCH = 'GET_ACCESS_CONTROLS_BY_SEARCH';
export const GET_ACCESS_CONTROL_BY_MODULE = 'GET_ACCESS_CONTROL_BY_MODULE';
export const GET_PERMISSION_BY_ROLE = 'GET_PERMISSION_BY_ROLE';
export const GET_PERMISSION_BY_ALL_ROLE = 'GET_PERMISSION_BY_ALL_ROLE';

export const INDUSTRY_GET_INDUSTRIES = 'INDUSTRY_GET_INDUSTRIES';
export const INIT_INDUSTRIES_LIST = 'INIT_INDUSTRIES_LIST';
export const GET_INDUSTRIES_BY_EXPAND = 'GET_INDUSTRIES_BY_EXPAND';
export const GET_INDUSTRIES_BY_SEARCH = 'GET_INDUSTRIES_BY_SEARCH';

export const SERVICE_GET_SERVICES = 'SERVICE_GET_SERVICES';
export const INIT_SERVICES_LIST = 'INIT_SERVICES_LIST';
export const GET_SERVICES_BY_EXPAND = 'GET_SERVICES_BY_EXPAND';
export const GET_SERVICES_BY_SEARCH = 'GET_SERVICES_BY_SEARCH';

export const CATEGORY_GET_CATEGORIES = 'CATEGORY_GET_CATEGORIES';
export const INIT_CATEGORIES_LIST = 'INIT_CATEGORIES_LIST'
export const GET_CATEGORIES_BY_EXPAND = 'GET_CATEGORIES_BY_EXPAND'
export const GET_CATEGORIES_BY_SEARCH = 'GET_CATEGORIES_BY_SEARCH'

export const SUB_CATEGORY_GET_SUB_CATEGORIES = 'SUB_CATEGORY_GET_SUB_CATEGORIES';
export const INIT_SUB_CATEGORIES_LIST = 'INIT_SUB_CATEGORIES_LIST'
export const GET_SUB_CATEGORIES_BY_EXPAND = 'GET_SUB_CATEGORIES_BY_EXPAND'
export const GET_SUB_CATEGORIES_BY_SEARCH = 'GET_SUB_CATEGORIES_BY_SEARCH'

export const INIT_ROLES_LIST = 'INIT_ROLES_LIST'
export const GET_ROLES_BY_EXPAND = 'GET_ROLES_BY_EXPAND'
export const GET_ROLES_BY_SEARCH = 'GET_ROLES_BY_SEARCH'

export const GET_LIKELYHOOD_BEFORE_CONTROL_GET = 'GET_LIKELYHOOD_BEFORE_CONTROL_GET';
export const GET_LIKELIHOOD_AFTER_CONTROL_GET = 'GET_LIKELIHOOD_AFTER_CONTROL_GET';
export const GET_CONSEQUENCE_BEFORE_CONTROL_GET = 'GET_CONSEQUENCE_BEFORE_CONTROL_GET';
export const GET_CONSEQUENCE_AFTER_CONTROL_GET = 'GET_CONSEQUENCE_AFTER_CONTROL_GET';
export const GET_LICENCES_TYPE = 'GET_LICENCES_TYPE'
export const VALIDATE_STATUS = 422;
export const GET_PDF_TEMPLATE = 'GET_PDF_TEMPLATE';
export const GET_PDF_TEMPLATE_DETAIL = 'GET_PDF_TEMPLATE_DETAIL';
export const GET_SMS_TEMPLATE_LIST = 'GET_SMS_TEMPLATE_LIST';
export const GET_SMS_TEMPLATE_DETAILS = 'GET_SMS_TEMPLATE_DETAILS';

export const SET_PWD = "SET_PWD";
export const MFA_STA = "MFA_STA";
export const LOGIN = "LOGIN";
export const FINISH = "FINISH";
export const NST_SHOW_MOBILE = "NST_SHOW_MOBILE";
export const NST_SHOW_EMAIL = "NST_SHOW_EMAIL";
export const UNAUTHORIZED = 403

export const JWT_SESSION_TOKEN = "JWT_SESSION_TOKEN";
export const JWT_ID_TOKEN = "JWT_ID_TOKEN";
export const JWT_ACCESS_TOKEN = "JWT_ACCESS_TOKEN";
export const USER_NAME = "USER_NAME";
export const JWT_REFRESH_TOKEN = "JWT_REFRESH_TOKEN";
export const FULL_NAME = 'FULL_NAME';
export const EMAIL_ADDRESS = 'EMAIL_ADDRESS';

export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE'
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const GET_EMAIL_TEMPLATE_DETAILS = 'GET_EMAIL_TEMPLATE_DETAILS'

export const INIT_LIKELYHOOD_BEFORE_CONTROL_LIST = 'INIT_LIKELYHOOD_BEFORE_CONTROL_LIST'
export const GET_LIKELYHOOD_BEFORE_CONTROL_BY_EXPAND = 'GET_LIKELYHOOD_BEFORE_CONTROL_BY_EXPAND'
export const GET_LIKELYHOOD_BEFORE_CONTROL_BY_SEARCH = 'GET_LIKELYHOOD_BEFORE_CONTROL_BY_SEARCH'

export const INIT_CONSEQUENCE_BEFORE_CONTROL_LIST = 'INIT_CONSEQUENCE_BEFORE_CONTROL_LIST'
export const GET_CONSEQUENCE_BEFORE_CONTROL_BY_EXPAND = 'GET_CONSEQUENCE_BEFORE_CONTROL_BY_EXPAND'
export const GET_CONSEQUENCE_BEFORE_CONTROL_BY_SEARCH = 'GET_CONSEQUENCE_BEFORE_CONTROL_BY_SEARCH'

export const INIT_LICENCES_LIST = 'INIT_LICENCES_LIST'
export const GET_LICENCES_BY_EXPAND = 'GET_LICENCES_BY_EXPAND'
export const GET_LICENCES_BY_SEARCH = 'GET_LICENCES_BY_SEARCH'

export const INIT_EMAIL_TEMPLATE_LIST = 'INIT_EMAIL_TEMPLATE_LIST'
export const GET_EMAIL_TEMPLATE_BY_EXPAND = 'GET_EMAIL_TEMPLATE_BY_EXPAND'
export const GET_EMAIL_TEMPLATE_BY_SEARCH = 'GET_EMAIL_TEMPLATE_BY_SEARCH'

export const SWMS_GET_ORG_SWMS = "SWMS_GET_ORG_SWMS";
export const SWMS_GET_SWMS_CONTROL = "SWMS_GET_SWMS_CONTROL";
export const TOOLBOX_TALK_ITEMS = "TOOLBOX_TALK_ITEMS"
export const TOOLBOX_TALK = "TOOLBOX_TALK"
export const INIT_COURSE_LIST = "INIT_COURSE_LIST";
export const GET_COURSE_LIST_BY_SEARCH = " GET_COURSE_LIST_BY_SEARCH";
export const GET_COURSE_LIST_BY_SEARCH_BY_EXPAND = "GET_COURSE_LIST_BY_SEARCH_BY_EXPAND";
export const GET_COURSE_MODULE_LIST = "GET_COURSE_MODULE_LIST";

export const AUTH_SIGNUP_URL = "/set-password";
export const AUTH_SIGNIN_URL = "/authenticate";
export const AUTH_CODE_URL = "/verify-second-step";
export const AUTH_FORGOT_PASS_URL = "/forgot-password";
export const AUTH_RESET_URL = "/reset-password";
export const AUTH_FORGOT_USER_URL = "/forgot-username";
export const ROLES_URL = "/role";
export const SUBSCRIPT_URL = "/subscription";
export const ORGANIZATION_URL = "/organisation";
export const ORG_USERS_URL = "/organisation-user";
export const ORG_USER_ROLE_URL = '/organisation-user-role';
export const ORG_BILLING_URL = "/org-biiling-details";
export const LOGOUT_URL = "/logout";
export const INVITE_USERS_URL = "/invite-user";
export const RESEND_CODE = "/resend-attribute-code";
export const ORG_USERS_PERMISSION_URL = "/permissions";
export const PAYMENT_URL = '/makePayment';
export const MODULE_URL = '/modules';
export const ACCESS_CONTROL_URL = '/access-control';
export const PERMISSION_URL = '/permissions';
export const ORG_UPDATE_CARD_URL = '/save-card-details';
export const GET_ROLE_PERMISSION_URL = '/permissions';
export const ORG_USERS_DELETE_URL = '/delete-org-user'
export const ORG_SUBSCRIPTION = '/org-subscription'


export const SMS_TEMPLATE_URL = '/sms-list';
export const GET_SMS_TEMPLATE_DETAIL_URL = '/sms-detail';
export const ADD_UPDATE_SMS_TEMPLATE_URL = '/sms';

export const GET_INDUSTRY_URL = '/industries';
export const SUBSCRIPTION_MODULES_URL = './subscription_modules';
export const GET_SERVICE_URL = '/services'
export const CATEGORIES_URL = '/categories'
export const SUB_CATEGORIES_URL = '/sub-categories'
export const GET_LIKELYHOOD_BEFORE_CONTROL_URL = '/likelihood-before-control'
export const GET_LIKELIHOOD_AFTER_CONTROL_URL = '/likelihood-after-control'
export const GET_CONSEQUENCE_BEFORE_CONTROL_URL = '/before-consequence'
export const GET_CONSEQUENCE_AFTER_CONTROL_URL = '/after-consequence'
export const GET_EMAIL_TEMPLATE_URL = '/email-template'
export const GET_EMAIL_TEMPLATE_DETAILS_URL = '/email-template-detail'
export const GET_LICENCES_TYPE_URL = '/license-types'
export const GET_PDF_TEMPLATE_URL = '/pdf-template'
export const GET_ALL_PDF_TEMPLATE_URL = '/all-pdf-template'
export const GET_PDF_TEMPLATE_DETAIL_URL = '/pdf-template-detail'
export const RENEW_ACCESS_URL = '/org-user-renew-access';
export const UPDATE_SA_INDUS_URL = '/org-industries'
export const DELETE_SA_INDUS = 'organisation-industries'
export const DELETE_SA_SERVICE = 'organisation-industry-services'
export const ADD_SWMS_URL = "/swms";
export const ADD_PPE_URL = "/ppe";
export const ADD_TOOL_URL = "/tools";
export const ADD_HRW_URL = "/high-risk-work";
export const ADD_CHEMICAL_URL = "/sds";
export const GET_ORG_SWMS = "/org-swms";
export const GET_ALL_SWMS_CONTROL_URL = "/swms-controls";
export const SWMS_CATEGORY_URL = '/swms-category'
export const TOOLBOX_TALK_ITEMS_URL = '/toolbox-talk-items'
export const TOOLBOX_TALK_URL = '/toolbox-talk'
export const GET_COURSES_LIST = '/course';
export const GET_COURSES_MODULE = '/course-module';
export const NEW_MODULE_URL = '/course-module';

// Additional Fields
export const GET_ADDITIONAL_FIELDS_LIST_URL = "/quote-additional-fields";
export const GET_ADDITIONAL_FIELDS_CATEGORIES_URL = "/quote-categories";
export const ADD_ADDITIONAL_FIELDS_URL = "/quote-additional-fields";
export const UPDATE_ADDITIONAL_FIELDS_STATUS_URL = "/quote-additional-fields"

export const ADDITIONAL_FIELDS_LIST = "ADDITIONAL_FIELDS_LIST";
export const ADDITIONAL_FIELDS_CATEGORIES = "ADDITIONAL_FIELDS_CATEGORIES";
