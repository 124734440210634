import React from 'react';
import { Icon, notification } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../../../actions/subscriptionAction';
import { Route } from 'react-router-dom';
import SubscriptionSearch from './subscriptionSearch';
import AddSubscription from './addSubscription';
import ViewEditSubscription from './viewEditSubscription';
import { Strings } from '../../../dataProvider/localize';
import { goBack } from '../../../utils/common'; 
const mapRouteToTitle = {
    '/dashboard/subscription/createSubscription': Strings.sub_add_new_title
}

class Subscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            togleSearch: true
        }
    }

    componentDidMount() {
        this.props.initSubscriptions()
            .then((flag) => {
                if (this.props.location.state && this.props.location.state.fromLink)
                    this.createSubscriptionHandler()
            })
            .catch((message) => {
                notification.error({
                    message: Strings.error_title,
                    description: message ? message : Strings.generic_error,
                    onClick: () => { },
                    className: 'ant-error'
                });
            });
    }

    createSubscriptionHandler = () => {
        this.props.history.push(this.props.match.path + '/createSubscription')
    }

    handleSearchToggle = () => {
        this.setState({ togleSearch: !this.state.togleSearch })
    }

    render() {

        return (
            <div>
                {/* inner header  */}
                <div className="dash-header">
                    <h2 className="page-mn-hd">
                        <Icon type="arrow-left" onClick={() => goBack(this.props)} />
                        {mapRouteToTitle[this.props.location.pathname] ? mapRouteToTitle[this.props.location.pathname] : Strings.sub_title}
                    </h2>
                    <div className="sf-steps-status d-none">
                        <div className="sf-st-item">
                            <Icon type="file-text" theme="filled" />
                            <span>Details</span>
                        </div>
                        <div className="sf-st-item">
                            <i className="fa fa-usd"></i>
                            <span>Billing</span>
                        </div>
                        <div className="sf-st-item">
                            <Icon type="usergroup-add" />
                            <span>Users</span>
                        </div>
                        <div className="sf-st-item sf-prog">
                            <i>0%</i>
                            <span>Progress</span>
                        </div>
                    </div>
                    <div className="oh-cont">
                        <button className="bnt bnt-active" onClick={this.createSubscriptionHandler}>{Strings.sub_create_new_btn}</button>
                    </div>
                </div>
                {/* inner header  */}
                <div className="main-container">
                    <div className="row">
                        {/* Left section */}
                        <SubscriptionSearch handleSearchToggle={this.handleSearchToggle} togleSearch={this.state.togleSearch} />
                        {/* center section  */}
                        <Route path={this.props.match.path + '/showSubscription'}
                            render={(props) => <ViewEditSubscription {...props} togleSearch={this.state.togleSearch} />} />
                        <Route path={this.props.match.path + '/createSubscription'}
                            render={(props) => <AddSubscription {...props} togleSearch={this.state.togleSearch} />} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscription.subscriptions,
    }
}

export default connect(mapStateToProps, actions)(Subscription)
