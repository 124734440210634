import axios from 'axios';
import { JWT_ACCESS_TOKEN, JWT_ID_TOKEN, USER_NAME, JWT_REFRESH_TOKEN, RENEW_ACCESS_URL } from '../dataProvider/constant';
import {
    BASE_API_URL, BASE_SCOPE_API_URL,
    BASE_SCOPE_API_V2, BASE_API_V2_URL,
} from '../dataProvider/env.config'
import { getStorage } from '../utils/common';
import { Store } from '../index';
import { logoutUser } from '../actions/index'
import { setStorage } from '../utils/common';

const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    },
})

const renewAccess = async () => {
    return axiosInstance.post(RENEW_ACCESS_URL)
        .then(res => {
            if (res.data.status) {
                //Restored JWT_REFRESH_TOKEN,JWT_ID_TOKEN and JWT_ACCESS_TOKEN
                setStorage(JWT_REFRESH_TOKEN, res.data.data.auth_data.refresh_token);
                setStorage(JWT_ID_TOKEN, res.data.data.auth_data.id_token);
                setStorage(JWT_ACCESS_TOKEN, res.data.data.auth_data.access_token);
                return Promise.resolve(true);
            }
        }).catch(error => {
            return Promise.resolve(false);
        })
}

axiosInstance.interceptors.request.use((request) => {
    return requestInterceptor(request);
}, (error) => {
    return Promise.reject(error);
});

// Axios Instance Admin V2
export const axiosV2Instance = axios.create({
    baseURL: BASE_API_V2_URL,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json'
    },
  })
  
  axiosV2Instance.defaults.params = {};
  
  axiosV2Instance.interceptors.request.use((request) => {
    return requestInterceptor(request)
  }, (error) => {
    return Promise.reject(error);
  });
  
  axiosV2Instance.interceptors.response.use((response) => {
    return response;
  }, async (error) => {
    // If request is UNAUTHORIZED then call token refresh web service and again call old web service with updated token
    const { status } = error.response;
    if (status === 401) {
      let res = await renewAccess();
      if (res) {
        return axiosInstance();
      }
    } else if (status === 403) {
      logoutUser(Store.dispatch);
      return Promise.reject(error);
    } else return Promise.reject(error);
  });

/**Scope doc axios instance */
export const scopeAxiosInstance = axios.create({
    baseURL: BASE_SCOPE_API_URL,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    },
})

scopeAxiosInstance.interceptors.request.use((request) => {
    return requestInterceptor(request);
}, (error) => {
    return Promise.reject(error);
});

const requestInterceptor = (request) => {
    request.headers.accessToken = getStorage(JWT_ACCESS_TOKEN);
    request.headers.accessId = getStorage(JWT_ID_TOKEN);
    request.headers.user_name = getStorage(USER_NAME);
    return request;
}

axiosInstance.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const { status } = error.response;
    // If request is UNAUTHORIZED then call token refresh web service 
    // and again call old web service with updated token

    if (status === 401) {
        let res = await renewAccess();
        if (res) {
            return axiosInstance();
        }
    } else if (status === 403) {
        logoutUser(Store.dispatch);
        return Promise.reject(error);
    } else return Promise.reject(error);
});

scopeAxiosInstance.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const { status } = error.response;
    // If request is UNAUTHORIZED then call token refresh web service 
    // and again call old web service with updated token


    if (status === 401) {
        let res = await renewAccess();
        if (res) {
            return axiosInstance();
        }
    } else if (status === 403) {
        logoutUser(Store.dispatch);
        return Promise.reject(error);
    } else return Promise.reject(error);
});

/**Scope doc V2 axios instance */
export const scopeV2AxiosInstance = axios.create({
    baseURL: BASE_SCOPE_API_V2,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json'
    },
  })
  
  scopeV2AxiosInstance.defaults.params = {}
  
  scopeV2AxiosInstance.interceptors.request.use((request) => {
    return requestInterceptor(request)
  }, (error) => {
    return Promise.reject(error);
  });
  
  scopeV2AxiosInstance.interceptors.response.use((response) => {
    return response;
  }, async (error) => {
    // If request is UNAUTHORIZED then call token refresh web service and again call old web service with updated token
    const { status } = error.response;
    if (status === 401) {
      let res = await renewAccess();
      if (res) {
        return axiosInstance();
      }
    } else if (status === 403) {
      logoutUser(Store.dispatch);
      return Promise.reject(error);
    } else return Promise.reject(error);
  });

export default axiosInstance;
