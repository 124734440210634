import React from 'react';
import {
    Icon,
    Menu,
    Dropdown,
    notification
} from 'antd';
import $ from 'jquery';
import { reduxForm, Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { validate } from '../../../utils/Validations/accessControlValidation';
import { customInput } from '../../common/custom-input';
import { CustomSwitch } from '../../common/customSwitch';
import * as actions from '../../../actions/accessControlManagementAction';
import { Strings } from '../../../dataProvider/localize';
import { CustomSelect } from '../../common/customSelect';
import { handleFocus, DeepTrim, accessType } from '../../../utils/common';

class EditAccessControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = { displayEdit: 'none', cardExpnadBtn: true }
    }

    onSubmit = async formData => {
        formData = await DeepTrim(formData);
        delete formData.module;
        delete formData.controller_name;
        delete formData.function_name;
        formData.active = +formData.active;
        this.props.updateAccessControl(formData).then((message) => {
            notification.success({
                message: Strings.success_title,
                description: message ? message : "",
                onClick: () => { },
                className: 'ant-success'
            });
            this.handleCancel();
        }).catch((message) => {
            notification.error({
                message: Strings.error_title,
                description: message ? message : Strings.generic_error,
                onClick: () => { },
                className: 'ant-error'
            });
        });
    }

    handleEditClick = () => {
        this.setState({ displayEdit: 'block' });
        if (!this.state.cardExpnadBtn) {
            this.handleExpand()
        }
    }

    handleCancel = () => {
        this.setState({ displayEdit: 'none' });
    }
    // expand center card----------
    handleExpand = () => {
        this.setState({ cardExpnadBtn: !this.state.cardExpnadBtn })
        $(".main-container").toggleClass("po-relative");
        $(".sf-card-wrap").toggleClass("fullscreen");
        $(".zoom-save-bnt").toggleClass("show-bnt");
    }

    render() {
        const { handleSubmit } = this.props;
        let accessControl = this.props.accessControls.find(access_control => access_control.id === this.props.location.state)
        let menu = (<Menu>
            <Menu.Item onClick={this.handleEditClick}>
                {Strings.menu_access_control_edit}
            </Menu.Item>
        </Menu>);

        return (
            <div className={this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"}>
                <div className="row">
                    <div className="col-lg-8 col-md-12 mb-4">
                        <div className="sf-card-wrap">
                            {/* zoom button  */}
                            <div className="card-expands">
                                <button type="button" onClick={this.handleExpand} className="exapnd-bnt normal-bnt">
                                    <Icon type={this.state.cardExpnadBtn ? "fullscreen" : "fullscreen-exit"} /></button>
                            </div>
                            <div className="sf-card">
                                <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                    <h2 className="sf-pg-heading">{Strings.access_control_card}</h2>
                                    <div className="info-btn">
                                        {/* Drop down for card */}
                                        <Dropdown className="more-info" overlay={menu}>
                                            <i className="material-icons">more_vert</i>
                                        </Dropdown>
                                        {/*Drop down*/}
                                    </div>
                                </div>

                                <div className="sf-card-body mt-2">

                                    <div className="data-v-row">
                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.access_control_title_name}</label>
                                                <span>{accessControl ? accessControl.title : ''}</span>
                                            </div>
                                        </div>

                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.access_control_status}</label>
                                                <span>{accessControl ? Boolean(accessControl.active).toString() : ''}</span>
                                            </div>
                                        </div>

                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.module_name}</label>
                                                <span>{accessControl ? accessControl.module.module_name : ''}</span>
                                            </div>
                                        </div>

                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{"Access Type"}</label>
                                                <span>{accessControl ? accessControl.access_type : ''}</span>
                                            </div>
                                        </div>
                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{"Description"}</label>
                                                <span>{accessControl ? accessControl.description : ''}</span>
                                            </div>
                                        </div>
                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{"Tool Tip"}</label>
                                                <span>{accessControl ? accessControl.tooltip : ''}</span>
                                            </div>
                                        </div>

                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.order}</label>
                                                <span>{accessControl && accessControl.sort_order ? accessControl.sort_order : ''}</span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Edit */}
                    <div className="col-lg-4 col-md-12" style={{ display: this.state.displayEdit }}>
                        <div className="sf-card">
                            <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                <h4 className="sf-sm-hd sf-pg-heading">Edit Access Control Details</h4>
                                <button className="closed-btn" onClick={this.handleCancel}><Icon type="close" /></button>
                            </div>
                            <div className="sf-card-body mt-2">
                                <form onSubmit={handleSubmit(this.onSubmit)} >
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.access_control_title_name}
                                            name="title"
                                            type="text"
                                            id="title"
                                            component={customInput} />
                                    </fieldset>
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.module_name}
                                            name="module_id"
                                            type="text"
                                            id="module_id"
                                            options={this.props.modules.map(moduleName => ({ title: moduleName.module_name, value: moduleName.id }))}
                                            component={CustomSelect} />
                                    </fieldset>
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.order}
                                            name="sort_order"
                                            type="number"
                                            id="sort_order"
                                            component={customInput} />
                                    </fieldset>
                                    <fieldset className="form-group sf-form">
                                            <Field
                                                label={"Access Type"}
                                                name="access_type"
                                                placeholder={"Select Access Type"}
                                                type="text"
                                                id="module_id"
                                                options={accessType}
                                                component={CustomSelect} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={"Description"}
                                                name="description"
                                                placeholder={"Description"}
                                                type="text"
                                                id="title"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={"Tool Tip"}
                                                name="tooltip"
                                                placeholder={"Tool tip text"}
                                                type="text"
                                                id="title"
                                                component={customInput} />
                                        </fieldset>
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            name="active"
                                            id="active"
                                            label={Strings.access_control_status}
                                            component={CustomSwitch} />
                                    </fieldset>

                                    <div className="all-btn multibnt">
                                        <div className="btn-hs-icon d-flex justify-content-between">
                                            <button onClick={this.handleCancel} className="bnt bnt-normal" type="button" disabled={!this.props.isDirty}>
                                                {Strings.cancel_btn}</button>
                                            <button type="submit" className="bnt bnt-active" disabled={!this.props.isDirty}>
                                                {Strings.update_btn}</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let value = state.accessControlManagement.accessControls.find(accessControl => accessControl.id === ownProps.location.state);
    return {
        accessControls: state.accessControlManagement.accessControls,
        modules: state.moduleManagement.modules,
        initialValues: value,
        isDirty: isDirty('editAccessControl')(state),
    }
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ form: 'editAccessControl', validate, enableReinitialize: true , onSubmitFail: (errors, dispatch, sub, props) => {
        handleFocus(errors, "#");
      }})
)(EditAccessControl)
