import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./auth";
// import dashboardReducer from './dashboardReducer';
import roleManagementReducer from "./roleManagementReducer";
import subscriptionReducer from "./subscriptionReducer";
import organizationReducer from "./organizationReducer";
import organizationUsersReducer from "./organizationUserReducer";
import organizationBillingReducer from "./organizationBillingReducer";
import moduleManagementReducer from './moduleManagementReducer';
import accessControlManagementReducer from './accessControlManagementReducer';
import permissionManagementReducer from './permissionManagementReducer';
import industryManagementReducer from './industryManagementReducer';
import serviceManagementReducer from './serviceManagementReducer';
import categoryManagementReducer from "./categoryManagementReducer";
import subCategoryManagementReducer from "./subCategoryManagementReducer";
import likelyhoodBeforeControlReducer from './likelyhoodBeforeControlReducer';
import likelihoodAfterControlReducer from './likelihoodAfterControlReducer';
import consequenceBeforeReducer from './consequenceBeforeReducer';
import consequenceAfterReducer from './consequenceAfterReducer';
import emailTemplateReducer from './emailTemplateReducer'
import LicencesTypeReducer from "./LicencesTypeReducer";
import pdfTemplateReducer from "./pdfTemplateReducer";
import smsTemplateReducer from "./smsTemplateReducer";
import swmsReducer from './swmsReducer';
import inductionTrainingReducer from "./inductionTrainingReducer";
import AdditionalFieldsReducer from "./v2/AdditionalFieldsReducer";

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  subscription: subscriptionReducer,
  organization: organizationReducer,
  emailTemplates: emailTemplateReducer,
  roleManagement: roleManagementReducer,
  moduleManagement: moduleManagementReducer,
  organizationUsers: organizationUsersReducer,
  serviceManagement: serviceManagementReducer,
  industryManagement: industryManagementReducer,
  categoryManagement: categoryManagementReducer,
  organizationBilling: organizationBillingReducer,
  afterConsequenceControl: consequenceAfterReducer,
  beforeConsequenceControl: consequenceBeforeReducer,
  subCategoryManagement: subCategoryManagementReducer,
  likelihoodAfterControl: likelihoodAfterControlReducer,
  likelyhoodBeforeControl: likelyhoodBeforeControlReducer,
  accessControlManagement: accessControlManagementReducer,
  permissionByRoleManagement: permissionManagementReducer,
  licencesType: LicencesTypeReducer,
  pdfTemplate: pdfTemplateReducer,
  smsTemplate: smsTemplateReducer,
  swmsReducer: swmsReducer,
  inductionTraining: inductionTrainingReducer,
  additionalFields: AdditionalFieldsReducer,

});
