import {
  SERVICE_GET_SERVICES,
  INIT_SERVICES_LIST,
  GET_SERVICES_BY_EXPAND,
  GET_SERVICES_BY_SEARCH
} from '../dataProvider/constant';

const DEFAULT_STATE = {
  services: [],
  currentPageNumber: 1,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SERVICE_GET_SERVICES:
      return {
        ...state,
        services: action.payload
      };

    case INIT_SERVICES_LIST:
      state.services = []
      return {
        ...state,
        services: action.payload,
        currentPageNumber: 1
      }

    case GET_SERVICES_BY_EXPAND:
      const services = [...state.services, ...action.payload]
      const updatedList = services.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
      });
      return {
        ...state,
        services: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }

    case GET_SERVICES_BY_SEARCH:
      return {
        ...state,
        services: action.payload,
        currentPageNumber: 1
      }

    default:
      return state;
  }
}