export const Strings = {

  /**Common */
  error_title: "Error!",
  generic_error: "Something went wrong please try again later",
  save_btn: "Save",
  search_placeholder: "Search",
  update_btn: 'Update',
  cancel_btn: 'Cancel',
  img_upload_text: 'Drag & Drop files here, or click to select',
  org_wizard_progress: 'Progress',
  network_error: "Network Error",
  profile_title: "Profile",
  logout_title: "Logout",
  pay_btn: 'Pay',
  success_title: 'Success',
  slug: 'Slug',
  validate_title: 'Validation',

  /** Sign In */
  sign_in_email_label: 'Enter username',
  sign_in_email_placeholder: 'Username',
  sign_in_password_label: 'Password',
  sign_in_password_placeholder: 'Your password',
  sign_in_forgot_password: 'Forgot your',
  sign_in_forgot_username: 'Username',
  sign_in_password: 'Password',
  sign_in_remember_me: 'Remember Me',
  sign_in_btn_sign_in: 'Sign In',
  sign_in_title_login: 'Login',

  /** Sign Up */
  sign_up_title: 'Sign Up',
  sign_up_password_label: 'Enter password',
  sign_up_confirm_password_label: 'Confirm password',
  sign_up_btn_sing_up: 'Sign Up',

  //Reset Password
  reset_pass_title: 'Reset Password',
  reset_pass_placeholder_enter_code: "Enter login code",
  reset_pass_placeholder_enter_password: 'Enter password',
  reset_pass_placeholder_cofirm_password: 'Confirm password',
  reset_pass_btn_sign_up: 'Sign Up',

  /** Login SMS Code*/
  login_code_title: 'Login',
  login_code_placeholder_enter_code: "Enter login code",
  login_code_btn_sign_in: 'Sign In',

  /** Forgot User Name* */
  forgot_username_title: 'Forgot username',
  forgot_username_placeholder_enter_username: "Enter username",
  forgot_username_placeholder_enter_mobile_no: "Please enter mobile number",
  forgot_username_placeholder_enter_email: "Please enter email id",
  forgot_username_btn_send_msg: 'Send Message',

  /** Forgot Password */
  forgot_pass_title: 'Forgot password',
  forgot_pass_placeholder_enter_username: "Enter username",
  forgot_pass_btn_send_msg: 'Send Message',

  //  dashboard/dashboard
  ddash_title: "Dashboard",
  dd_intro_btn: "Intro video",
  dd_welcome_lg_hd: "Welcome to Service Farm, Brett!",
  dd_letsget_sm_hd: "Let's get you started with your Online Induction",
  dd_pls_sure_para: "Please make sure that you have at least half an hour to complete the induction. You can choose to come back later and complete the induction your progress will be saved.",
  dd_mpdal_para: "Vivamus facilisis, tellus luctus dictum auctor, massa mauris elementum enim, ut iaculis neque.",

  /**Organisation strings */
  add_org_title: "Add New Organisation",
  org_title: "Organisations",
  org_wizard_detail: "Details",
  org_wizard_user: 'Users',
  org_wizard_sub: 'Subscription',
  org_add_btn: 'Create New Organisation',
  user_table_name: "Name",
  user_table_email: "Email",
  user_table_phone: "Phone number",
  user_table_role: "User Role",
  user_table_sms: 'Sms code',
  user_table_email_code: 'Email code',
  user_role_placeholder: "Select user",
  add_user_btn: 'Add New User',
  org_detail_card: 'Organisation Details',
  org_name: "Organisation Name",
  org_pri_person: "Primary Contact Person",
  org_phone: "Phone",
  org_email: "Email",
  org_address: "Address",
  org_abn: "ABN/ACN",
  org_user_card: "Users",
  sub_card: "Subscription Level",
  sub_type: "Subscription Type",
  sub_percentage: "Discount %",
  sub_discount_period: "Discount period",
  sub_date: "Subscription start date",
  sub_discount_amt: "Amount after discount",
  view_all_org: "View All Organisations",
  perm_scope_doc: 'Create Scope Doc',
  perm_job_book: 'Book Job in Calendar',
  perm_generate_quote: 'Generate Quote',
  perm_send_quote: 'Send Quote for Approval',
  perm_generate_doc: 'Generate Job Docs',
  role_staff: "Staff",
  perm_card_title: 'User Permission',
  perm_info_text: 'Permission are visible when a use is selected from the users panel',
  perm_title: 'User Permission',
  user_add: "Added",
  user_update: 'Updated',
  user_error: 'Error',
  user_dublicate: 'Duplicate',
  user_imported_by: 'Imported by',
  user_started: 'Started',
  user_completed: 'Completed',
  user_import_summary: 'Import Summary for',
  user_bulk_import_que: 'Want to Bulk Import ?',
  user_bulk_import_info: "Easily upload users by importing records in .csv format. You can click here to download simple CSV template ready to import.",
  user_wizard_permissions: 'Permissions',
  user_wizard_user: "Users",
  invite_btn: 'Invite',
  menu_user_mgmt: 'User Management',
  menu_edit_bill: 'Edit billing details',
  menu_update_payment: 'Update payment info',
  menu_update_subscription_level: 'Update Subscription Level',
  menu_edit_org: 'Edit Details',
  bill_card: 'Billing Details',
  edit_org_card: 'Edit Organisation Details',
  org_edit_img: 'Edit Image',
  user_list_delete_alert: "Cannot perform deletion,\n As organisation requires atleast one Admin",
  user_list_confirm_delete_alert: "Are you sure you want to delete this user?",
  sub_invoice_date: "Invoice Start Date",
  website_txt: "Website",
  notification_email_txt: "Notification Email",
  notification_email_sad: 'email@address.com',
  add_org_industry_btn: "Add Industry",


  // Subscription
  sub_hd_title: "Subscription Details",
  sub_f_name: "Subscription Name",
  sub_f_amount: "Subscription Amount",
  sub_f_period: "Subscription Period",
  sub_add_new_title: "Add New Subscription",
  sub_title: "Subscriptions",
  sub_create_new_btn: "Create New Subscription",
  sub_view_all_btn: "View All Subscriptions",
  sub_details_title: "Subscription Details",
  sub_edit_dtl_title: "Edit Subscription Details",
  sub_create_success: "Subscription created successfully",
  subscript_status: 'Active',
  sub_f_status: "Subscription Status",
  subscript_visibility: "Org - SA Subscription",


  // Dashboard
  das_help_center_title: "Help Center",
  das_learning_roadmap: "Learning Roadmap",
  das_video_help: "Video Help",

  // Navigation Menu
  role_manage_title: "Role Management",

  // Profile
  tab_personal_dtl: "Personal Details",
  tab_payroll_dtl: "Payroll Details",
  tab_licences: "Licences",
  tab_medical_dclr: "Medical Declaration",
  tab_rostering: "Rostering",



  /**Role Management */
  role_title: "Role Management",
  add_role_btn: "Create New Role",
  add_role_title: 'Add New Role',
  view_all_role: 'View All Roles',
  menu_role_edit: 'Edit Role',
  role_card: 'Role Details',
  role_name: "Role Name",
  role_status: 'Active',
  role_create_success: "Role Created successfully",
  role_update_success: "Role Permission Updated successfully",
  role_default: 'Default Role',
  role_job_calendar_lables: "Job Calendar Lables",

  // Billing details
  add_pay_meth_title: "Add Payment Method",
  update_pay_title: "Update Payment Info",
  card_name_title: "Name on Card",
  card_number_pay: "Card Number",
  card_expdate: "Expiry",
  _: "Csub_VC/VV",
  switch_pay_mtod: "Switch Payment Method",
  save_card: 'Save card',

  // checkbox style
  read_only_txt: "Read Only",
  modify_txt: "Modify",
  crate_txt: "Create",
  delete_txt: "Delete",
  all_txt: "All",
  admin_txt: "Admin",

  role_edit_title: "Edit Permission",
  role_quote_management_txt: "Quote Management",
  role_finance_management: "Finance Management",
  role_add_new_pesmission: "Add New Permission",

  // profile

  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  dob_txt: "Date of Birth",
  gender_txt: "Gender",
  username_txt: "Username",
  mobile_no_txt: "Mobile Number",
  phone_no_txt: "Phone Number",
  primary_email_txt: "Primary Email",
  role_txt: "Role",
  res_address_txt: "Residential Address",
  street_add_txt: "Street Address",
  city_txt: "City",
  state_txt: "State",
  zip_code_no: "Zip Code",
  country_txt: "Country",
  experience_txt: "Experience",
  area_of_expertise: "Area of Expertise",
  hours_of_experinace: "Hours of Experience",
  emergency_contact_dtl: "Emergency Contact Details",
  full_name_txt: "Full Name",
  relationship_txt: "Relationship",
  email_txt: "Email",

  /** Module Management */

  module_title: "Module Management",
  add_module_btn: "Create New Modules",
  add_module_title: 'Add New Module',
  view_all_module: 'View All Modules',
  menu_module_edit: 'Edit Module',
  module_card: 'Module Details',
  module_name: "Module Name",
  module_status: 'Active',
  module_show_in_sidebar: 'Show In Sidebar',
  module_icon_name: 'Icon Name',

  /** Access Control Management */

  access_control_title: "Access Control Management",
  add_access_control_btn: "Create New Access Control",
  add_access_control_title: 'Add New Access Control',
  view_all_access_control: 'View All Access Control',
  menu_access_control_edit: 'Edit Access Control',
  access_control_card: 'Access Control Details',
  access_control_name: "Access Control Name",
  access_control_title_name: "Access Control Title",
  access_control_status: 'Active',
  access_control_controller_name: 'Access Controller Name',
  access_control_function_name: 'Access Control Function Name',
  invite_users: "Invite Users",
  order: "Order",

  /**  Permission */
  permission: "Permissions",

  /** Module Management */
  industry_title: "Industry Management",
  add_industry_btn: "Create New Industry",
  add_industry_title: 'Add New Industry',
  view_all_industry: 'View All Industries',
  menu_industry_edit: 'Edit Industry',
  industry_card: 'Industry Details',
  industry_name: "Industry Name",
  industry_status: 'Active',
  industry_details: "Industry Details",
  edit_industry_details_title: "Edit Industry Details",

  /* Sub Industry Management*/

  sub_industry_title: "Sub Industry Management",
  add_sub_industry_btn: "Create New Sub Industry",
  add_sub_industry_title: 'Add New Sub Industry',
  view_all_sub_industry: 'View All Sub Industries',
  menu_sub_industry_edit: 'Edit Sub Industry',
  sub_industry_card: 'Sub Industry Details',
  sub_industry_name: "Sub Industry Name",
  sub_industry_status: 'Active',
  sub_industry_details: "Sub Industry Details",
  edit_sub_industry_details_title: "Edit Sub Industry Details",

  /** Service Management */
  service_title: "Service Management",
  add_service_btn: "Create New Service",
  add_service_title: 'Add New Service',
  view_all_service: 'View All Services',
  menu_service_edit: 'Edit Service',
  service_card: 'Service Details',
  service_name: "Service Name",
  service_status: 'Active',
  service_details: "Service Details",
  edit_service_details_title: "Edit Service Details",

  /* Category Management */

  category_title: "Category Management",
  add_category_btn: "Create New Category",
  add_category_title: 'Add New Category',
  view_all_categories: 'View All Categories',
  menu_category_edit: 'Edit Category',
  category_card: 'Category Details',
  category_name: "Category Name",
  category_status: 'Active',
  category_details: "Category Details",
  edit_category_details_title: "Edit Category Details",

  /* Sub-Category Management */

  sub_category_title: "Sub-Category Management",
  add_sub_category_btn: "Create New Sub-Category",
  add_sub_category_title: 'Add New Sub-Category',
  view_all_sub_categories: 'View All Sub-Categories',
  menu_sub_category_edit: 'Edit Sub-Category',
  sub_category_card: 'Sub-Category Details',
  sub_category_name: "Sub-Category Name",
  sub_category_status: 'Active',
  sub_category_details: "Sub-Category Details",
  edit_sub_category_details_title: "Edit Sub-Category Details",

  /*Likelyhood Before Control */
  likelyhoodbeforecontrol_title: "Likelihood",
  add_likelyhoodbeforecontrol_btn: "Create New Likelihood",
  add_likelyhoodbeforecontrol_title: "Add New Likelihood",
  likelyhoodbeforecontrol_details: "Likelihood Details",
  likelyhoodbeforecontrol_name: "Likelihood Name",
  likelyhoodbeforecontrol_status: 'Active',
  edit_likelyhoodbeforecontrol_details_title: 'Edit Likelihood',
  likelyhoodbeforecontrol_card: 'Likelihood Details',
  control_view_all_btn: "View All Controls",
  menu_control_edit: 'Edit Before Control',

  /*Consequence Before Control */
  consequencebeforecontrol_title: "Consequence",
  add_consequencebeforecontrol_btn: "Create New Consequnce",
  add_consequencebeforecontrol_title: "Add New Consequnce",
  edit_consequencebeforecontrol_btn: "Edit Consequence",
  consequencebeforecontrol_details: "Consequence Details",
  consequencebeforecontrol_name: "Consequence Name",
  consequencebeforecontrol_status: 'Active',
  edit_consequencebeforecontrol_details_title: 'Edit Consequence',
  consequencebeforecontrol_card: 'Consequence Details',


  /* Email Template Management */
  email_template_title: "Email Templates",
  add_template_btn: "Create New Template",
  edit_template_btn: "Edit Template",
  email_template_details: "Template Details",
  view_all_templates: 'View All Templates',
  from_txt: 'From',
  to_txt: 'To',
  subject_txt: 'Subject',
  message_txt: 'Message',

  /* Licences Type */
  licences_type_title: "Licences Type",
  add_licences_type_btn: "Create New Licence",
  edit_licences_type_btn: "Edit Licence",
  licences_type_details: "Licences Details",
  view_all_licences_type: 'View All Licences',
  licences_type_name: 'Licences Type Name',
  licences_type_status: 'Active',
  /* PDF Template Management */
  pdf_template_title: "PDF Templates",
  add_pdf_template_btn: "Create New Template",
  edit_pdf_template_btn: "Edit Template",
  pdf_template_details: "PDF Template Details",
  view_all_pdf: 'View All PDF Templates',
  pdf_template_name: 'PDF Template Name',
  pdf_content: 'Content',
  pdf_template_status: 'Active',
  /* SMS Temlate */
  sms_template_title: "SMS Template",
  add_sms_template_btn: "Create New SMS",
  sms_view_all_btn: "View all template.",
  edit_sms_template_btn: "Edit SMS Template",
  sms_template_name: "SMS Template Name",
  sms_template_status: 'Active',
  edit_sms_template_title: 'Edit SMS Template',
  sms_template_card: 'SMS Template Details',
  sms_template_content: 'Content',

  // Placeholders -----------------------

  subject: 'Email subject',
  temp_name: 'Name for template to be displayed to Orgs',
  email_address: 'email@address.com',
  predefined_slug: 'Predefined slug',

  licences_name: 'Licence type',

  consequence_name: 'Consequence',

  likelihood_name: 'Likelihood',

  industry_id: 'Select Industry',
  service_id: 'Select Service',
  category_id: 'Select Category',
  
  industry_name_cat_m: 'Select Industry',
  service_name_cat_m: 'Select Service',
  category_name_cat_m: 'Category name',

  industry_name_ser_m: 'Select Industry',
  service_name_ser_m: 'Service name',

  industry_name_indu_m: 'Industry name',

  module_id_acm: 'Select Module',
  title_acm: 'Title',
  controller_name_acm: 'Controller name',
  function_name_acm: 'Function name',
  sort_order_acm: 'Display order',

  module_name_md_m: 'Module name',

  name_subsc: 'Subscription name',
  amount_subsc: 'Billable amount',
  period_subsc: 'Billing period (months)',

  primary_person_org: 'Contact name',
  address_org: 'Organisation address',
  abn_acn_org: 'xx xxx xxx xxx',
  email_address_org: 'email@address.com',
  billing_address_org: 'Billing address',
  name_user_org: 'Full name',
  phone_number_user_org: 'Number for contact and SMS',
  role_id_user_org: 'Role',

  subscription_id: 'Select a subscription',
  sub_discount: '% to be discounted',
  sub_period: 'No. of days applied',

  card_number_upi: 'xxxx xxxx xxxx xxxx',
  card_name_upi: 'Card name',
  card_expiry_month_upi: 'mm',
  card_expiry_year_upi: 'yy',
  card_sub_cvv: 'cvv',
  img_drag_drop_text: 'or drag & drop them here',

  // Service Agent
  new_service_agent: "New Service Agent",
  service_agent_dtl: "Service Agent Details",
  short_code_txt: "Short Code",
  service_inductries_txt: "Service Industries",
  service_inductry_txt: "Service Industry",
  sub_industry_txt: "Sub-Industry",
  sub_category_txt: "Sub-Category",
  connected_org_txt: "Connected Organisations",
  services_txt: "Services",
  category_txt: "Category",
  edit_industry_menu: "Edit Indstries",
  edit_indstry_details: "Edit Service Agent Industry",
  edit_sa_card: "Edit Service Agent Details",
  instry_header_text: "Industry",
  choose_service: "Choose Service",
  connect_btn: "Connect",
  view_all_service_agents: "View All Service Agents",
  category_table_header: "Choose Category",
  sub_category_table_header: "Choose Sub-Category",
  sa_add_btn: "Create New Service Agent",
  job_calendar_templates: 'Job calendar lables',
  // SWMS Management
  swms_management: 'SWMS Management',

  /* Induction & Training */
  ind_create_course: "Create Course",
  ind_add_new_course: "Add New Course",
  ind_name: "Name",
  adminUserName: 'ServiceFarm Admin',
  adminEmailAddress: 'info@service.farm',
}

export const ValidationStrings = {
  /*Access Control Validation */
  access_control_validate_title: 'Please enter a title for the access control.',
  access_control_validate_select_module: 'Please select a module.',
  access_control_validate_name_controller: 'Please enter a name for the controller.',
  access_control_validate_funtion_name: 'Please enter a function name.',

  /** Billing Validation */
  billing_validation_name: 'Please enter the card name.',
  billing_validation_card_num: 'Please enter the card number. ',
  billing_validation_invalid_card: 'Invalid card number',
  billing_validation_expiry_month: 'Please enter the card expiry month',
  billing_validation_invalid_month: 'Invalid expiry month',
  billing_validation_expiry_year: 'Please enter the card expiry year',
  billing_validation_invalid_year: 'Invalid expiry year',
  billing_validation_cvv_num: 'Please enter card CVC/CVV.',
  billing_validation_invalid_cvv: 'Invalid CVC/CVV number',
  billing_validation_email: "Please enter an email address.",
  billing_validation_invalid_email: "Invalid email address",
  billing_validation_address: 'Please enter the billing address. ',

  /**Category Validation */
  category_validation_category_name: 'Please enter the category name.',
  category_validation_industry_name: 'Please select an industry.',
  category_validation_service_name: 'Please select a service.',

  /** Consequences After Validations */
  consequence_after_control_name: 'Please Fill the Control Name',

  /** Consequences Before Validations */
  consequence_before_control_name: 'Please Fill the Control Name',

  /** Industry Validation*/
  industry_validation_name: 'Please enter an industry name.',

  /** Likelihood Before Validations */
  likelihood_after_control_name: 'Please enter a likelihood label.',

  /** Likelihood Before Validations */
  likelihood_before_control_name: 'Please enter a consequence label.',

  /** Module Validation */
  module_validation_module_name: 'Please enter a name for the module.',
  module_validation_slug: 'Please enter the slug text for the module.',
  module_validation_icon_name: 'Please enter the icon name for the module.',
  /** Organization Validation */
  organization_validation_name: "Please enter the organisation name.",
  organization_validation_primary_person: "Please enter the primary contact name.",
  organization_validation_phone_number: "Please enter a contact phone number.",
  organization_validation_email_address: "Please enter a contact email address.",
  organization_validation_abn_acn: "Please enter an ABN/ACN.",
  organization_validation_invalid_abn_acn: "Invalid ABN/ACN.",
  organization_validation_user_name: "Please enter the user's name.",
  organization_validation_user_email_address: "Please enter the user's email address.",
  organization_validation_user_phone_number: "Please enter the user's mobile number.",
  organization_validation_subscription_id: "This is a required field",
  organization_validation_invalid_phone_number: "Invalid phone number.",
  organization_validation_invalid_email_address: "Invalid email address.",
  organization_validation_role_id: "This is a required field",
  profile_street_address_empty: 'Please enter your street address.',

  /** Role Validation */
  role_validation_name: 'Please enter a name for the role.',

  /** Service Validation */
  service_validate_name: 'Please enter a service name.',

  /** Sub-Category Validation */
  sub_category_validate_name: 'Please enter the sub-category name',
  sub_category_validate_industry_name: 'Please select an industry.',
  sub_category_validate_service_name: 'Please select a service.',
  sub_category_validate_category_name: 'Please select a category.',

  /**Subscription Validation */
  subscription_validation_name: 'Please enter a name for the subscription. ',
  subscription_validation_period: 'Please enter the Priod',
  subscription_validation_valid_period: 'Period can be 3, 6, 12 months only',
  subscription_validation_amount: 'Please enter an amount for the subscription. ',

  /**Udate Single user Validation*/
  update_single_user_validation_name: 'Please enter the name',
  update_single_user_validation_email_address: "Please enter the email address.",
  update_single_user_validation_phone_number: "Please enter the phone number.",
  update_single_user_validation_invalid_phone_number: "Invalid phone number.",
  update_single_user_validation_invalid_email_address: "Invalid email address.",
  update_single_user_validation_role: 'Please select the role',

  /* PDF template validation */
  pdf_slug_empty: 'Please enter the slug.',
  pdf_temp_name_empty: 'Please enter the template name.',
  pdf_content_empty: 'Please enter the content.',

  /* SMS Template Validation */
  sms_slug_empty: 'Please enter the slug.',
  sms_temp_name_empty: 'Please enter the template name.',
  sms_content_empty: 'Please enter the content.',

  //Course Validations
  course_name: 'Please enter the name.',
  course_short_description: 'Please enter the short description.',
  course_detailed_description: 'Please enter the course description.',
  course_modules: 'Please select course module.',
  course_expiration: 'Please enter the expiration.',
  course_org_role: 'Please select organisation roles.',
  course_tags: 'Please select tags.'
}

export const validationString = {
  /* SWMS validate */
  swms_name_empty: 'This is a required field.',
  swms_expiry_empty: 'This is a required field.',
  swms_active_empty: 'This is a required field.',
  swms_hazard_empty: 'This is a required field.',
  swms_likelihood_before_controls_empty: 'This is a required field.',
  swms_consequence_before_controls_empty: 'This is a required field.',
  swms_control_measures_empty: 'This is a required field.',
  swms_likelihood_after_controls_empty: 'This is a required field.',
  swms_consequence_after_controls_empty: 'This is a required field.',
  swms_person_responsible_empty: 'This is a required field',

}
