import {
  SERVICE_GET_SERVICES,
  GET_SERVICE_URL,
  INIT_SERVICES_LIST,
  GET_SERVICES_BY_EXPAND,
  GET_SERVICES_BY_SEARCH,
} from '../dataProvider/constant';
import {
  startSipnner, stopSipnner,
  startMiniSpinner, stopMiniSpinner,
  startScrollSpinner, stopScrollSpinner
} from '../utils/spinner';
import axiosInstance from '../dataProvider/axiosHelper';
import { Strings } from '../dataProvider/localize';

const getServicesList = dispatch => {
  let url = GET_SERVICE_URL
  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: SERVICE_GET_SERVICES,
          payload: res.data.data.services
        })
        return Promise.resolve(true)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const getServices = () => dispatch => {
  startSipnner(dispatch);
  return getServicesList(dispatch);
}

export const initServices = () => dispatch => {
  return initAllServicesApi(dispatch)
}

const initAllServicesApi = dispatch => {
  startSipnner(dispatch)

  const searchKey = '';
  const pageNumber = 1;

  const url = `${GET_SERVICE_URL}?search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INIT_SERVICES_LIST,
          payload: res.data.data.services
        })
        return Promise.resolve()
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const searchExpandServicesList = (searchKey = '', pageNumber = 1, searching, scrolling) => dispatch => {

  searching ? startMiniSpinner(dispatch) : scrolling ? startScrollSpinner(dispatch) : startSipnner(dispatch)

  const url = `${GET_SERVICE_URL}?search_key=${searchKey}&page=${pageNumber}`
  return axiosInstance.get(url)
    .then(res => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      if (res.data.status && !searching) {
        dispatch({
          type: GET_SERVICES_BY_EXPAND,
          payload: res.data.data.services,
        })
        return Promise.resolve(res.data.data.services.length)
      } else {
        dispatch({
          type: GET_SERVICES_BY_SEARCH,
          payload: res.data.data.services,
        })
        return Promise.resolve(res.data.data.services.length)
      }
    })
    .catch(error => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const addService = (formData) => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(GET_SERVICE_URL, formData)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        initAllServicesApi(dispatch)
        return Promise.resolve(res.data.message)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateService = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(GET_SERVICE_URL, formData)
    .then(res => {
      stopSipnner(dispatch)
      if (res.data.status) {
        getServicesList(dispatch)
        return Promise.resolve(true)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}
