import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon, Dropdown, notification } from 'antd';
import {
    reduxForm,
    Field
} from 'redux-form';
import $ from 'jquery';

import { validate } from '../../../utils/Validations/moduleValidation';
import { customInput } from '../../common/custom-input';
import * as actions from '../../../actions/moduleManagementAction';
import { CustomSwitch } from '../../common/customSwitch'
import { Strings } from '../../../dataProvider/localize';
import { handleFocus, DeepTrim } from '../../../utils/common';

class AddNewModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cardExpnadBtn: true }
    }
    onSubmit = async (formData) => {
        formData = await DeepTrim(formData);

        formData.active = +formData.active;
        this.props.addModule(formData).then((message) => {
            notification.success({
                message: Strings.success_title,
                description: message ? message : '',
                onClick: () => { },
                className: 'ant-success'
            });
            this.props.reset();
        }).catch((message) => {
            notification.error({
                message: Strings.error_title,
                description: message ? message : Strings.generic_error,
                onClick: () => { },
                className: 'ant-error'
            });
        });
    }

    // expand center card----------
    handleExpand = () => {
        this.setState({ cardExpnadBtn: !this.state.cardExpnadBtn })
        $(".main-container").toggleClass("po-relative");
        $(".sf-card-wrap").toggleClass("fullscreen");
        $(".zoom-save-bnt").toggleClass("show-bnt");
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <div className={this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"}>
                <div className="row">
                    <div className="col-md-12 col-lg-8">
                        <form onSubmit={handleSubmit(this.onSubmit)}>
                            <div className="sf-card-wrap">
                                {/* zoom button  */}
                                <div className="card-expands">
                                    <button type="button" onClick={this.handleExpand} className="exapnd-bnt normal-bnt">
                                        <Icon type={this.state.cardExpnadBtn ? "fullscreen" : "fullscreen-exit"} /></button>
                                </div>
                                <div className="sf-card">
                                    <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                        <h2 className="sf-pg-heading">Module Details</h2>
                                        <div className="info-btn disable-dot-menu">
                                            <Dropdown className="more-info" disabled overlay={''}>
                                                <i className="material-icons">more_vert</i>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="sf-card-body mt-2">
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.module_name}
                                                name="module_name"
                                                placeholder={Strings.module_name_md_m}
                                                type="text"
                                                id="module_name"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.slug}
                                                name="slug"
                                                placeholder={Strings.predefined_slug}
                                                type="text"
                                                id="slug"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.module_icon_name}
                                                name="icon_name"
                                                type="text"
                                                id="icon_name"
                                                component={customInput} />
                                        </fieldset>
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                                <fieldset className="form-group sf-form">
                                                    <Field
                                                        name="show_in_sidebar"
                                                        id="show_in_sidebar"
                                                        label={Strings.module_show_in_sidebar}
                                                        component={CustomSwitch} />
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                                <fieldset className="form-group sf-form">
                                                    <Field
                                                        name="active"
                                                        id="active"
                                                        label={Strings.module_status}
                                                        component={CustomSwitch} />
                                                </fieldset>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* zoom save button  */}
                                <div className="row zoom-save-bnt">
                                    <div className="col-md-12">
                                        <div className="all-btn d-flex justify-content-end mt-4">
                                            <div className="btn-hs-icon">
                                                <button type="submit" className="bnt bnt-active">
                                                    <i className="material-icons">save</i> {Strings.save_btn}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="all-btn d-flex justify-content-end mt-4">
                                <div className="btn-hs-icon">
                                    <button type="submit" className="bnt bnt-active">
                                        <i className="material-icons">save</i> {Strings.save_btn}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modules: state.moduleManagement.modules,
        initialValues: { active: true, show_in_sidebar: true }
    }
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ form: 'addNewModule', validate: validate , onSubmitFail: (errors, dispatch, sub, props) => {
        handleFocus(errors, "#");
      }})
)(AddNewModule)
