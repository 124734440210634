import { ValidationStrings } from './../../dataProvider/localize'

export const validate = values => {
    const errors = {}

    if (Array.isArray(values.module_id) && values.module_id.length === 0) {
        errors.module_id = ValidationStrings.access_control_validate_select_module
    }

    if (!values.title) {
        errors.title = ValidationStrings.access_control_validate_title
    }
    if (!values.module_id) {
        errors.module_id = ValidationStrings.access_control_validate_select_module
    }
    if (!values.controller_name) {
        errors.controller_name = ValidationStrings.access_control_validate_name_controller
    }
    if (!values.function_name) {
        errors.function_name = ValidationStrings.access_control_validate_funtion_name
    }
    return errors
}
