import React from 'react';
import {
    Icon,
    Menu,
    Dropdown,
    
    notification
} from 'antd';
import { reduxForm, Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import $ from 'jquery';

import { validate } from '../../../utils/Validations/moduleValidation';
import { customInput } from '../../common/custom-input';
import { CustomSwitch } from '../../common/customSwitch';
import * as actions from '../../../actions/moduleManagementAction';
import { Strings } from '../../../dataProvider/localize';
import { handleFocus, DeepTrim } from '../../../utils/common';

class EditModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = { displayEdit: 'none', cardExpnadBtn: true }
    }

    onSubmit = async formData => {
        formData = await DeepTrim(formData);
        formData.active = +formData.active;
        this.props.updateModule(formData).then((message) => {
            notification.success({
                message: Strings.success_title,
                description: message ? message : "",
                onClick: () => { },
                className: 'ant-success'
            });
            this.handleCancel();
        }).catch((message) => {
            notification.error({
                message: Strings.error_title,
                description: message ? message : Strings.generic_error,
                onClick: () => { },
                className: 'ant-error'
            });
        });
    }

    handleEditClick = () => {
        this.setState({ displayEdit: 'block' });
        if (!this.state.cardExpnadBtn) {
            this.handleExpand()
        }
    }

    handleCancel = () => {
        this.setState({ displayEdit: 'none' });
    }

    // expand center card----------
    handleExpand = () => {
        this.setState({ cardExpnadBtn: !this.state.cardExpnadBtn })
        $(".main-container").toggleClass("po-relative");
        $(".sf-card-wrap").toggleClass("fullscreen");
        $(".zoom-save-bnt").toggleClass("show-bnt");
    }

    render() {
        const { handleSubmit } = this.props;
        let modulesName = this.props.modules.find(module_name => module_name.id === this.props.location.state)

        let menu = (<Menu>
            <Menu.Item onClick={this.handleEditClick}>
                {Strings.menu_module_edit}
            </Menu.Item>
        </Menu>);

        return (
            <div className={this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"}>
                <div className="row">
                    <div className="col-lg-8 col-md-12 mb-4">
                        <div className="sf-card-wrap">
                            {/* zoom button  */}
                            <div className="card-expands">
                                <button type="button" onClick={this.handleExpand} className="exapnd-bnt normal-bnt">
                                    <Icon type={this.state.cardExpnadBtn ? "fullscreen" : "fullscreen-exit"} /></button>
                            </div>
                            <div className="sf-card">
                                <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                    <h2 className="sf-pg-heading">{Strings.module_card}</h2>
                                    <div className="info-btn">
                                        {/* Drop down for card */}
                                        <Dropdown className="more-info" overlay={menu}>
                                            <i className="material-icons">more_vert</i>
                                        </Dropdown>
                                        {/*Drop down*/}
                                    </div>
                                </div>

                                <div className="sf-card-body mt-2">

                                    <div className="data-v-row">
                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.module_name}</label>
                                                <span>{modulesName ? modulesName.module_name : ''}</span>
                                            </div>
                                        </div>

                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.slug}</label>
                                                <span>{modulesName && modulesName.slug ? modulesName.slug : ''}</span>
                                            </div>
                                        </div>
                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.module_icon_name}</label>
                                                <span>{modulesName && modulesName.icon_name ? modulesName.icon_name : ''}</span>
                                            </div>
                                        </div>
                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.module_status}</label>
                                                <span>{modulesName ? Boolean(modulesName.active).toString() : ''}</span>
                                            </div>
                                        </div>

                                        <div className="data-v-col">
                                            <div className="view-text-value">
                                                <label>{Strings.module_show_in_sidebar}</label>
                                                <span>{modulesName ? Boolean(modulesName.show_in_sidebar).toString() : ''}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Edit */}
                    <div className="col-lg-4 col-md-12" style={{ display: this.state.displayEdit }}>
                        <div className="sf-card">
                            <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                <h4 className="sf-sm-hd sf-pg-heading">Edit Module Details</h4>
                                <button className="closed-btn" onClick={this.handleCancel}><Icon type="close" /></button>
                            </div>
                            <div className="sf-card-body mt-2">
                                <form onSubmit={handleSubmit(this.onSubmit)} >
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.module_name}
                                            name="module_name"
                                            type="name"
                                            id="module_name"
                                            component={customInput} />
                                    </fieldset>

                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.slug}
                                            name="slug"
                                            type="text"
                                            id="slug"
                                            component={customInput} />
                                    </fieldset>

                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.module_icon_name}
                                            name="icon_name"
                                            type="text"
                                            id="icon_name"
                                            component={customInput} />
                                    </fieldset>

                                    <fieldset className="form-group sf-form">
                                        <Field
                                            name="show_in_sidebar"
                                            id="show_in_sidebar"
                                            label={Strings.module_show_in_sidebar}
                                            component={CustomSwitch} />
                                    </fieldset>

                                    <fieldset className="form-group sf-form">
                                        <Field
                                            name="active"
                                            id="active"
                                            label={Strings.module_status}
                                            component={CustomSwitch} />
                                    </fieldset>

                                    <div className="all-btn multibnt">
                                        <div className="btn-hs-icon d-flex justify-content-between">
                                            <button onClick={this.handleCancel} className="bnt bnt-normal" type="button" disabled={!this.props.isDirty}>
                                                {Strings.cancel_btn}</button>
                                            <button type="submit" className="bnt bnt-active" disabled={!this.props.isDirty}>
                                                {Strings.update_btn}</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let value = state.moduleManagement.modules.find(moduleName => moduleName.id === ownProps.location.state);
    return {
        modules: state.moduleManagement.modules,
        initialValues: value,
        isDirty: isDirty('editModule')(state),
    }
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ form: 'editModule', validate: validate, enableReinitialize: true , onSubmitFail: (errors, dispatch, sub, props) => {
        handleFocus(errors, "#");
      }})
)(EditModule)


