import validator from 'validator';
import { ValidationStrings } from './../../dataProvider/localize'

/** Card Validations */
export const validate = values => {
    const errors = {}
    if (!values.card_name) {
        errors.card_name = ValidationStrings.billing_validation_name
    }

    if (!values.card_number) {
        errors.card_number = ValidationStrings.billing_validation_card_num

    } else if (typeof values.card_number === "string" && !validator.isNumeric(values.card_number)) {
        errors.card_number = ValidationStrings.billing_validation_invalid_card
    }

    if (Array.isArray(values.card_expiry_month) && values.card_expiry_month.length === 0) {
        errors.card_expiry_month = ValidationStrings.billing_validation_invalid_month
    }

    if (!values.card_expiry_month) {
        errors.card_expiry_month = ValidationStrings.billing_validation_expiry_month

    } else if (typeof values.card_expiry_month === "string" && !validator.isNumeric(values.card_expiry_month)) {
        errors.card_expiry_month = ValidationStrings.billing_validation_invalid_month
    }

    if (Array.isArray(values.card_expiry_year) && values.card_expiry_year.length === 0) {
        errors.card_expiry_year = ValidationStrings.billing_validation_invalid_year
    }

    if (!values.card_expiry_year) {
        errors.card_expiry_year = ValidationStrings.billing_validation_expiry_year

    } else if (typeof values.card_expiry_year === "string" && !validator.isNumeric(values.card_expiry_year)) {
        errors.card_expiry_year = ValidationStrings.billing_validation_invalid_year
    }

    if (!values.card_cvv) {
        errors.card_cvv = ValidationStrings.billing_validation_cvv_num

    } else if (!validator.isNumeric(values.card_cvv) || (values.card_cvv.length && values.card_cvv.length < 3)) {
        errors.card_cvv = ValidationStrings.billing_validation_invalid_cvv;
    }

    return errors
}

/** Billing Address Validations */
export const billingAddressValidate = values => {
    const errors = {}

    if (!values.billing_email_address) {
        errors.billing_email_address = ValidationStrings.billing_validation_email

    } else if (!validator.isEmail(values.billing_email_address)) {
        errors.billing_email_address = ValidationStrings.billing_validation_invalid_email
    }

    if (!values.billing_address) {
        errors.billing_address = ValidationStrings.billing_validation_address
    }

    return errors
}