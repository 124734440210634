import {
    GET_SMS_TEMPLATE_LIST,
    GET_SMS_TEMPLATE_DETAILS
} from "../dataProvider/constant";

const DEFAULT_STATE = {
    smsTemplateList: [],
    smsTemplateDetails: []
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case GET_SMS_TEMPLATE_LIST:
            return {
                ...state,
                smsTemplateList: action.payload
            }
        case GET_SMS_TEMPLATE_DETAILS:
            return{
                ...state,
                smsTemplateDetails: action.payload
            }
        default:
            return state
    }
}
