import {
  SUBSCRIPT_GET_SUBSCRIPTS,
  SUBSCRIPT_URL,
  SUBSCRIPTION_MODULES_URL,
  INIT_SUBSCRIPT_LIST,
  GET_SUBSCRIPT_BY_EXPAND,
  GET_SUBSCRIPT_BY_SEARCH
} from '../dataProvider/constant'
import {
  startSipnner, stopSipnner,
  startMiniSpinner, stopMiniSpinner,
  startScrollSpinner, stopScrollSpinner
} from '../utils/spinner';
import axiosInstance from '../dataProvider/axiosHelper'
import { Strings } from '../dataProvider/localize'


const getAllSubscriptionApi = dispatch => {
  return axiosInstance.get(SUBSCRIPT_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: SUBSCRIPT_GET_SUBSCRIPTS,
          payload: res.data.data.subscriptions
        })
        return Promise.resolve(true)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const getSubscription = () => dispatch => {
  startSipnner(dispatch);
  return getAllSubscriptionApi(dispatch);
}

export const addSubscription = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(SUBSCRIPT_URL, formData)
    .then(res => {
      if (res.data.status) {
        initAllSubscriptionsApi(dispatch)
        return Promise.resolve(res.data.data.subscription);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateSubscription = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(SUBSCRIPT_URL, formData)
    .then(res => {
      if (res.data.status) {
        getAllSubscriptionApi(dispatch)
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateSubscriptionModules = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(SUBSCRIPTION_MODULES_URL, formData)
    .then(res => {
      if (res.data.status) {
        getAllSubscriptionApi(dispatch)
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const initSubscriptions = () => dispatch => {
  return initAllSubscriptionsApi(dispatch)
}

const initAllSubscriptionsApi = dispatch => {
  startSipnner(dispatch)

  const searchKey = '';
  const pageNumber = 1;

  const url = `${SUBSCRIPT_URL}?search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INIT_SUBSCRIPT_LIST,
          payload: res.data.data.subscriptions
        })
        return Promise.resolve()
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const searchExpandSubscriptionsList = (searchKey = '', pageNumber = 1, searching, scrolling) => dispatch => {

  searching ? startMiniSpinner(dispatch) : scrolling ? startScrollSpinner(dispatch) : startSipnner(dispatch)

  const url = `${SUBSCRIPT_URL}?search_key=${searchKey}&page=${pageNumber}`
  return axiosInstance.get(url)
    .then(res => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      if (res.data.status && !searching) {
        dispatch({
          type: GET_SUBSCRIPT_BY_EXPAND,
          payload: res.data.data.subscriptions,
        })
        return Promise.resolve(res.data.data.subscriptions.length)
      } else {
        dispatch({
          type: GET_SUBSCRIPT_BY_SEARCH,
          payload: res.data.data.subscriptions,
        })
        return Promise.resolve(res.data.data.subscriptions.length)
      }
    })
    .catch(error => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}