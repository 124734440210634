import {
	SWMS_GET_ORG_SWMS, SWMS_GET_SWMS_CONTROL, TOOLBOX_TALK_ITEMS, TOOLBOX_TALK
} from '../dataProvider/constant';

const DEFAULT_STATE = {
	orgSWMS: {},
	swmsControl: {},
	toolboxTalkItems: [],
	toolboxTalk: []
}

export default (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case SWMS_GET_ORG_SWMS:
			return { ...state, orgSWMS: action.payload }
		case SWMS_GET_SWMS_CONTROL:
			return { ...state, swmsControl: action.payload }
		case TOOLBOX_TALK_ITEMS:
			return { ...state, toolboxTalkItems: action.payload }
		case TOOLBOX_TALK:
			return { ...state, toolboxTalk: action.payload }
		default:
			return state;
	}
}