import {
  MODULE_GET_MODULES,
  INIT_MODULES_LIST,
  GET_MODULES_BY_EXPAND,
  GET_MODULES_BY_SEARCH
} from '../dataProvider/constant';

const DEFAULT_STATE = {
  modules: [],
  currentPageNumber: 1,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case MODULE_GET_MODULES:
      return {
        ...state,
        modules: action.payload
      };
    case INIT_MODULES_LIST:
      state.modules = []
      return {
        ...state,
        modules: action.payload,
        currentPageNumber: 1
      }
    case GET_MODULES_BY_EXPAND:
      const modules = [...state.modules, ...action.payload]
      const updatedList = modules.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
      });
      return {
        ...state,
        modules: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }
    case GET_MODULES_BY_SEARCH:
      return {
        ...state,
        modules: action.payload,
        currentPageNumber: 1
      }
    default:
      return state;
  }
}