import {
  GET_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATE_DETAILS, INIT_EMAIL_TEMPLATE_LIST,
  GET_EMAIL_TEMPLATE_BY_EXPAND,
  GET_EMAIL_TEMPLATE_BY_SEARCH
} from '../dataProvider/constant';

const INITIAL_STATE = {
  currentPageNumber: 1,
  emailTemplateList: [],
  emailTemplateDetails: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplateList: action.payload
      }
    case GET_EMAIL_TEMPLATE_DETAILS:
      return {
        ...state,
        emailTemplateDetails: action.payload
      }
    case INIT_EMAIL_TEMPLATE_LIST:
      state.emailTemplateList = []
      return {
        ...state,
        emailTemplateList: action.payload,
        currentPageNumber: 1
      }
    case GET_EMAIL_TEMPLATE_BY_EXPAND:
      const emailTemplateList = [...state.emailTemplateList, ...action.payload]
      const updatedList = emailTemplateList.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.slug).indexOf(obj.slug) === pos;
      });
      return {
        ...state,
        emailTemplateList: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }
    case GET_EMAIL_TEMPLATE_BY_SEARCH:
      return {
        ...state,
        emailTemplateList: action.payload,
        currentPageNumber: 1
      }
    default:
      return {
        ...state
      }
  }
}