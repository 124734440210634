import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {store} from './utils/createStore'
import App from './App';
import './assets/css/bootstrap.min.css';
import './App.css';
import	'./assets/css/dashboard.css';
import './assets/css/responsive.css';
import './assets/fonts/simple-line-icons.css';
import './assets/css/ionicons.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

export const Store = store;
ReactDOM.render(
	<Provider store={store}>
		<App/>
	</Provider>,
	document.getElementById('root')
	);