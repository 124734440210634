import {
  CATEGORY_GET_CATEGORIES,
  INIT_CATEGORIES_LIST,
  GET_CATEGORIES_BY_EXPAND,
  GET_CATEGORIES_BY_SEARCH
} from '../dataProvider/constant'

const DEFAULT_STATE = {
  categories: [],
  currentPageNumber: 1
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CATEGORY_GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }

    case INIT_CATEGORIES_LIST:
      state.categories = []
      return {
        ...state,
        categories: action.payload,
        currentPageNumber: 1
      }

    case GET_CATEGORIES_BY_EXPAND:
      const categories = [...state.categories, ...action.payload]
      const updatedList = categories.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
      });
      return {
        ...state,
        categories: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }

    case GET_CATEGORIES_BY_SEARCH:
      return {
        ...state,
        categories: action.payload,
        currentPageNumber: 1
      }

    default:
      return state;
  }
}