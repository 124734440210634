import {
  INDUSTRY_GET_INDUSTRIES,
  GET_INDUSTRY_URL,
  INIT_INDUSTRIES_LIST,
  GET_INDUSTRIES_BY_EXPAND,
  GET_INDUSTRIES_BY_SEARCH
} from '../dataProvider/constant';
import {
  startSipnner, stopSipnner,
  startMiniSpinner, stopMiniSpinner,
  startScrollSpinner, stopScrollSpinner
} from '../utils/spinner';
import axiosInstance from '../dataProvider/axiosHelper';
import { Strings } from '../dataProvider/localize';

const getAllIndustryApi = dispatch => {
  return axiosInstance.get(GET_INDUSTRY_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INDUSTRY_GET_INDUSTRIES,
          payload: res.data.data.industries
        })
        return Promise.resolve(true)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const getIndustries = () => dispatch => {
  startSipnner(dispatch);
  return getAllIndustryApi(dispatch);

}

export const addIdustry = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(GET_INDUSTRY_URL, formData)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        initAllIndustriesApi(dispatch)
        return Promise.resolve(true)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateIndustry = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(GET_INDUSTRY_URL, formData)
    .then(res => {
      stopSipnner(dispatch)
      if (res.data.status) {
        getAllIndustryApi(dispatch)
        return Promise.resolve(true)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const initIndustries = () => dispatch => {
  return initAllIndustriesApi(dispatch)
}

const initAllIndustriesApi = dispatch => {
  startSipnner(dispatch)

  const searchKey = '';
  const pageNumber = 1;

  const url = `${GET_INDUSTRY_URL}?search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INIT_INDUSTRIES_LIST,
          payload: res.data.data.industries
        })
        return Promise.resolve()
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const searchExpandIndustriesList = (searchKey = '', pageNumber = 1, searching, scrolling) => dispatch => {

  searching ? startMiniSpinner(dispatch) : scrolling ? startScrollSpinner(dispatch) : startSipnner(dispatch)

  const url = `${GET_INDUSTRY_URL}?search_key=${searchKey}&page=${pageNumber}`
  return axiosInstance.get(url)
    .then(res => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      if (res.data.status && !searching) {
        dispatch({
          type: GET_INDUSTRIES_BY_EXPAND,
          payload: res.data.data.industries,
        })
        return Promise.resolve(res.data.data.industries.length)
      } else {
        dispatch({
          type: GET_INDUSTRIES_BY_SEARCH,
          payload: res.data.data.industries,
        })
        return Promise.resolve(res.data.data.industries.length)
      }
    })
    .catch(error => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}
