import { ValidationStrings } from './../../dataProvider/localize'

export const validate = values => {
    const errors = {}

    if (!values.module_name) {
        errors.module_name = ValidationStrings.module_validation_module_name
    }
    if (!values.slug) {
        errors.slug = ValidationStrings.module_validation_slug
    }

    if(values.show_in_sidebar){
        if(!values.icon_name){
            errors.icon_name = ValidationStrings.module_validation_icon_name
        }
    }
    return errors
}
