import {
  INDUSTRY_GET_INDUSTRIES,
  INIT_INDUSTRIES_LIST,
  GET_INDUSTRIES_BY_EXPAND,
  GET_INDUSTRIES_BY_SEARCH
} from '../dataProvider/constant';

const DEFAULT_STATE = {
  industries: [],
  currentPageNumber: 1,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case INDUSTRY_GET_INDUSTRIES:
      return {
        ...state,
        industries: action.payload
      };

    case INIT_INDUSTRIES_LIST:
      state.industries = []
      return {
        ...state,
        industries: action.payload,
        currentPageNumber: 1
      }

    case GET_INDUSTRIES_BY_EXPAND:
      const industries = [...state.industries, ...action.payload]
      const updatedList = industries.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
      });
      return {
        ...state,
        industries: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }

    case GET_INDUSTRIES_BY_SEARCH:
      return {
        ...state,
        industries: action.payload,
        currentPageNumber: 1
      }

    default:
      return state;
  }
}