import React from 'react';
import { Icon, Modal } from 'antd';
import { Strings } from '../../../dataProvider/localize';
import { goBack } from '../../../utils/common';

class DashBoard extends React.Component {
    
    // Video Tutorial popup Modal

    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div className="sf-page-layout">
                {/* inner header  */}
                <div className="dash-header">
                    <h2 className="page-mn-hd"><Icon type="arrow-left" onClick={() => goBack(this.props)} /> {Strings.ddash_title}</h2>
                    <div className="oh-cont">
                        <button className="bnt bnt-active" onClick={this.showModal}>{Strings.dd_intro_btn}</button>
                    </div>
                </div>
                {/* inner header  */}

                <div className="main-container">
                    <h3 className="dashb-heading">{Strings.dd_welcome_lg_hd}</h3>
                    <div className="sf-card p-4">
                        <div className="sf-card-head sf-dashb">
                            <h4>{Strings.dd_letsget_sm_hd}</h4>
                            <p>{Strings.dd_pls_sure_para}</p>
                            <button className="closed-btn"><Icon type="close" /></button>
                        </div>

                        <div className="row">

                            <div className="col-md-4 col-sm-6">
                                <div className="sf-card sf-dash-items">
                                <i className="anticon material-icons">description</i>
                                    <h5>Set up your Profile</h5>
                                    <p>Vivamus facilisis, tellus luctus dictum auctor, massa mauris elementum enim, ut iaculis neque.</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="sf-card sf-dash-items">
                                    <i className="material-icons">attach_money</i>
                                    <h5>Set up your Payroll Details</h5>
                                    <p>Vivamus facilisis, tellus luctus dictum auctor, massa mauris elementum enim, ut iaculis neque.</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="sf-card sf-dash-items">
                                    <i className="anticon material-icons">perm_contact_calendar</i>
                                    <h5>Upload your Licences/Certificates</h5>
                                    <p>Vivamus facilisis, tellus luctus dictum auctor, massa mauris elementum enim, ut iaculis neque.</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="sf-card sf-dash-items">
                                    <i className="material-icons">healing</i>
                                    <h5>Sign your Medical Declaration</h5>
                                    <p>Vivamus facilisis, tellus luctus dictum auctor, massa mauris elementum enim, ut iaculis neque.</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="sf-card sf-dash-items">
                                    <i className="fa fa-calendar-minus-o" aria-hidden="true"></i>
                                    <h5>Set up your Rostering</h5>
                                    <p>Vivamus facilisis, tellus luctus dictum auctor, massa mauris elementum enim, ut iaculis neque.</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="sf-card sf-dash-items">
                                    <i className="fa fa-columns" aria-hidden="true"></i>
                                    <h5>Complete your Induction</h5>
                                    <p>Vivamus facilisis, tellus luctus dictum auctor, massa mauris elementum enim, ut iaculis neque.</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <Modal className="sf-intro-modal" width="800px" title={
                    <div className="sf-modal-hd"><h3 className="dashb-heading">{Strings.dd_welcome_lg_hd}</h3>
                    <p>{Strings.dd_mpdal_para}</p></div>
                    }
                    visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <div className="intro-video">
                        <iframe
                            src="https://player.vimeo.com/video/1084537"
                            width="100%"
                            height="400"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen
                            title='intro-video'>
                        </iframe>
                    </div>
                </Modal>

            </div>
        );
    }
}

export default DashBoard
