import React from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Icon, Badge, Dropdown, Avatar } from 'antd';
import { NAV_MENU } from './navigationMenu';
import { Route, Link, Switch } from 'react-router-dom';
import UserManagement from './organization/UserManagement';
import * as action from '../../actions';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import InviteUsers from './organization/InviteUsers';
import { Strings } from '../../dataProvider/localize';
import { JWT_ACCESS_TOKEN, JWT_ID_TOKEN } from '../../dataProvider/constant';
import { getStorage } from '../../utils/common';
import ScrollArea from 'react-scrollbar';
import $ from 'jquery';

const { Header, Sider, Content } = Layout;


// for notification dropdown list
const notification = (
	<Menu>
		<Menu.Item key="0">
			<a href=";" target="_blank" rel="noopener noreferrer" >
				First Notification
		</a>
		</Menu.Item>
		<Menu.Divider />
		<Menu.Item key="1">
			<a href=";" target="_blank" rel="noopener noreferrer">
				Second Notification
		</a>
		</Menu.Item>
		<Menu.Divider />
		<Menu.Item key="3">
			<a href=";" target="_blank" rel="noopener noreferrer" >
				Thired Notification
		</a>
		</Menu.Item>
	</Menu>
);

const sfHelp = (
	<Menu>
		<Menu.Item key="0">
			<a href=";" target="_blank" rel="noopener noreferrer">
				{Strings.das_help_center_title}
			</a>
		</Menu.Item>
		<Menu.Divider />
		<Menu.Item key="1">
			<a href=";" target="_blank" rel="noopener noreferrer">
				{Strings.das_learning_roadmap}
			</a>
		</Menu.Item>
		<Menu.Divider />
		<Menu.Item key="3">
			<a href=";" target="_blank" rel="noopener noreferrer">
				{Strings.das_video_help}
			</a>
		</Menu.Item>
	</Menu>
);

export class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			broken: false,
			showSearch: false,
			showSearchBox: false
		};
		if (getStorage(JWT_ACCESS_TOKEN)
			&& getStorage(JWT_ID_TOKEN)) {
			this.props.history.push(this.props.match.path + '/dashboard');
		}
	}
	/**Acting as auth guard */
	componentDidUpdate() {
		if (!getStorage(JWT_ACCESS_TOKEN)
			&& !getStorage(JWT_ID_TOKEN)) {
			this.props.history.push('/signin');
		}

		// for nav scroll
		let onResize = function () {
			let $windowHeight = $(window).height() - 100;
			if ($('.sf-page-layout').height() < 600) {
				$('.scrollarea.menu-scroll').css('height', ($windowHeight));
				$('.ant-layout').css('min-height', ($windowHeight + 100));
			} else {
				$('.scrollarea.menu-scroll').css('height', '');
			}
		}
		$(document).ready(onResize);
		$(window).resize(onResize);
		// end
	}

	/* for nav scroll */
	componentDidMount() {
		$(function () {
			$(".ant-layout-has-sider").css({ minHeight: $(window).innerHeight() + 'px' });
			$(window).resize(function () {
				$(".ant-layout-has-sider").css({ minHeight: $(window).innerHeight() + 'px' });
			});
		});
	}

	handleUserManageClick = () => {
		this.props.action.signOut();
		this.props.history.push({ pathname: '/signin' })
	}

	toggle = (evt) => {
		evt.stopPropagation()
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	breakpointToggle = broken => {
		this.setState({ broken: broken });
	}

	// will use it very soon
	handleSearchClick = () => {
		this.setState({ showSearch: true })
	}

	handleSelection() {
		const menuItemNumber = NAV_MENU.findIndex(item => {
			return item.linkTo.split('/')[1] === this.props.location.pathname.split('/')[2];
		})
		return [menuItemNumber.toString()];
	}

	openSider = () => this.setState({ collapsed: false })

	closeSider = () => this.setState({ collapsed: true })

	render() {
		// for user profile dropdown list
		const menu = (
			<Menu>
				<Menu.Item key="0">
					<a target="blank" rel="noopener noreferrer" href=";" className="user-details">
						<strong className="user-name">{Strings.adminUserName}</strong>
						<span className="user-id">{Strings.adminEmailAddress}</span>
					</a>
				</Menu.Item>
				<Menu.Divider />
				<Menu.Item key="3" onClick={this.handleUserManageClick}>
					<i className="fa fa-sign-out"></i>  {Strings.logout_title}
				</Menu.Item>
			</Menu>
		);

		return (

			<Layout>
				<Sider
					width={280}
					onMouseOver={this.openSider}
					onMouseOut={this.closeSider}
					breakpoint="sm"
					collapsedWidth={this.state.broken ? "0" : "80"}
					onBreakpoint={this.breakpointToggle}
					trigger={null}
					collapsible
					collapsed={this.state.collapsed}>
					<div className="logo dsb-logo"><img alt="" src="/images/sf-logo.png" /><strong>Service Farm</strong></div>
					<ScrollArea speed={0.8} smoothScrolling={true}
						className="sf-scroll menu-scroll" horizontal={false}>
						<Menu className="sf-nav" theme="dark" mode="inline" defaultSelectedKeys={['0']} selectedKeys={this.handleSelection()}>
							{/* .sort((a, b) => {
								if (a.title.toLowerCase() < b.title.toLowerCase())
									return -1;
								if (a.title.toLowerCase() > b.title.toLowerCase())
									return 1;
								return 0
							}) */}
							{NAV_MENU.map((item, index) =>
								(
									<Menu.Item key={index} className="sf-nav-items">
										<Link to={{ pathname: this.props.match.path + item.linkTo, state: { fromLink: true } }} onClick={this.closeSider}>

											{item.iconType()}
											<span>{item.title}</span>

										</Link>
									</Menu.Item>
								)
							)}
						</Menu>
					</ScrollArea>
				</Sider>
				<Layout onClick={this.closeSider}
					className={this.state.broken && !this.state.collapsed ? "sideBarOverlay" : ""}>
					<Header>
						<button className="trigger navbar-bnt normal-bnt" type={'menu'} onClick={this.toggle}>
							<i className="material-icons">menu</i>
						</button>
						<div className="usr-profile d-flex">
							<div className="sett-badge search-badge">
								<span className={this.state.showSearchBox ? "searchFfrom show-search-box" : "searchFfrom"}>
									{this.state.showSearchBox ? <div className="page-search-box">
										<input type="text" placeholder="search" className="toggle-textbox" />
										<button onClick={() => this.setState({ showSearchBox: false })} type="button" className="normal-bnt"><i className="material-icons">close</i></button>
									</div> : null}
									<button onClick={() => this.setState({ showSearchBox: true })} className="normal-bnt srch-button">
										<i className="ion-search"></i></button>
								</span>
							</div>
							<div className="sett-badge">
								<Dropdown overlay={notification}>
									<a className="ant-dropdown-link notifi-drop no-dots" href=";">
										<Badge dot><i className="material-icons">notifications</i>
										</Badge>
									</a>
								</Dropdown></div>

							<div className="sett-badge">
								<Dropdown overlay={sfHelp}>
									<a className="ant-dropdown-link notifi-drop" href=";">
										<i className="ion-help-circled"></i>
									</a>
								</Dropdown></div>

							<div className="sett-badge pro-sett">
								<Dropdown overlay={menu}>
									<a className="ant-dropdown-link" href=";">
										<Avatar src="/images/tony.png" />
										<span className="usr-txt">{Strings.adminUserName}</span><Icon type="caret-down" />
									</a>
								</Dropdown></div>
						</div>
					</Header>
					<Content>
						{/**For auth guarding  */
							getStorage(JWT_ACCESS_TOKEN) && getStorage(JWT_ID_TOKEN) ?
								<Switch>
									{NAV_MENU.map((item, index) => (<Route key={index} path={this.props.match.path + item.linkTo} component={item.component} />))}
									<Route path={this.props.match.path + '/userManagement'} component={UserManagement} />
									<Route path={this.props.match.path + '/inviteUsers'} component={InviteUsers} />
								</Switch> : null
						}
					</Content>
				</Layout>
			</Layout>

		);
	}
}

const mapStateToProps = state => {
	return {

	}
}

const mapDispatchToprops = dispatch => {
	return {
		action: bindActionCreators(action, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToprops)(Dashboard))
