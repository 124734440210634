import React from 'react';
import { Icon, notification } from 'antd';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as actions from '../../../actions/accessControlManagementAction';
import AccessControlSearch from './AccessControlSearch';
import AddNewAccessControl from './AddNewAccessControl';
import EditAccessControl from './EditAccessControl';
import { Strings } from '../../../dataProvider/localize';
import * as moduleAction from '../../../actions/moduleManagementAction';
import { goBack } from '../../../utils/common';
const mapRouteToTitle = {
  '/dashboard/accessControlsManagement/createAccessControl': Strings.add_access_control_title
}

class AccessControlManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { togleSearch: true }
  }

  componentDidMount() {
    this.props.modulesAction.getModules();
    this.props.action.initAccessControls()
      .then((flag) => {
        if (this.props.location.state && this.props.location.state.fromLink)
        this.createAccessControlHandler()
      }).catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: 'ant-error'
        });
      });
  }

  createAccessControlHandler = () => {
    this.props.history.push(this.props.match.path + '/createAccessControl')
  }

  handleSearchToggle = () => {
    this.setState({ togleSearch: !this.state.togleSearch })
  }

  render() {

    return (
      <div className="sf-page-layout">
        {/* inner header  */}
        <div className="dash-header">
          <h2 className="page-mn-hd">
            <Icon type="arrow-left" onClick={() => goBack(this.props)} />
            {mapRouteToTitle[this.props.location.pathname] ? mapRouteToTitle[this.props.location.pathname] : Strings.access_control_title}
          </h2>

          <div className="oh-cont">
            <button className="bnt bnt-active" onClick={this.createAccessControlHandler}>{Strings.add_access_control_btn}</button>
          </div>
        </div>
        {/* inner header  */}
        <div className="main-container">
          <div className="row">
            {/* Left section */}
            <AccessControlSearch handleSearchToggle={this.handleSearchToggle} togleSearch={this.state.togleSearch} />
            {/* center section  */}
            <Route path={this.props.match.path + '/showAccessControl'}
              render={(props) => <EditAccessControl {...props} togleSearch={this.state.togleSearch} />} />
            <Route path={this.props.match.path + '/createAccessControl'}
              render={(props) => <AddNewAccessControl {...props} togleSearch={this.state.togleSearch} />} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accessControls: state.accessControlManagement.accessControls,
  }
}

const mapDispatchToprops = dispatch => {
  return {
    action: bindActionCreators(actions, dispatch),
    modulesAction: bindActionCreators(moduleAction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToprops)(AccessControlManagement)
