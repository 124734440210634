import {
    GET_CONSEQUENCE_AFTER_CONTROL_GET
} from '../dataProvider/constant';

const DEFAULT_STATE = {
    consequenceAfterControls: [],
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type){
        case GET_CONSEQUENCE_AFTER_CONTROL_GET:
            return { ...state, consequenceAfterControls: action.payload };
        default:
            return state;
    }
}