
export const validate = values => {
    const errors = {}

    if (!values.subscription_id) {
        errors.subscription_id = "This is a required field";
    }

    if (!values.invoice_start_date) {
        errors.invoice_start_date = "This is a required field";
    }

    

    return errors
}