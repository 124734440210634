export const validate = values => {
	const errors = {}
	if (!values.user_name) {
		errors.user_name = 'Please enter username';
	}
	// else if (values.user_name.length < 8) {
	// 	errors.user_name = 'Must be at least 8 characters';
	// }

	if (!values.password) {
		errors.password = 'Please enter password';
	} else if (values.password.length < 8) {
		errors.password = 'Must be at least 8 characters';
	}

	if (!values.confirmPassword) {
		errors.confirmPassword = 'Please confirm your password';
	} else if (values.confirmPassword !== values.password) {
		errors.confirmPassword = 'Passwords don\'t match'
	}

	if (!values.password) {
		errors.password = 'Please enter password';
	} else if (values.password.length < 8) {
		errors.password = 'Must be at least 8 characters';
	}

	if (!values.login_code) {
		errors.login_code = 'Please enter login code';
	} else if (isNaN(Number(values.login_code))) {
		errors.login_code = 'Must be a number';
	}

	if (!values.user_full_name) {
		errors.user_full_name = 'Please enter full name';
	}

	if (!values.email_address) {
		errors.email_address = 'Please enter email address'
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_address)) {
		errors.email_address = 'Invalid email address'
	}
	if (!values.new_password) {
		errors.new_password = 'Please enter password'
	} else if (values.new_password.length < 8) {
		errors.new_password = 'Must be at least 8 characters';
	}
	if (!values.confirm_password) {
		errors.confirm_password = 'Please confirm your password';
	} else if (values.confirm_password !== values.new_password) {
		errors.confirm_password = 'Passwords don\'t match'
	}
	return errors
}
