import {
  SUBSCRIPT_GET_SUBSCRIPTS,
  INIT_SUBSCRIPT_LIST,
  GET_SUBSCRIPT_BY_EXPAND,
  GET_SUBSCRIPT_BY_SEARCH
} from '../dataProvider/constant';

const DEFAULT_STATE = {
  subscriptions: [],
  currentPageNumber: 1,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SUBSCRIPT_GET_SUBSCRIPTS:
      return {
        ...state,
        subscriptions: action.payload
      }

    case INIT_SUBSCRIPT_LIST:
      state.subscriptions = []
      return {
        ...state,
        subscriptions: action.payload,
        currentPageNumber: 1
      }

    case GET_SUBSCRIPT_BY_EXPAND:
      const subscriptions = [...state.subscriptions, ...action.payload]
      const updatedList = subscriptions.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.subscription_id).indexOf(obj.subscription_id) === pos;
      });
      return {
        ...state,
        subscriptions: updatedList,
        currentPageNumber: state.currentPageNumber + 1,
      }

    case GET_SUBSCRIPT_BY_SEARCH:
      return {
        ...state,
        subscriptions: action.payload,
        currentPageNumber: 1
      }

    default:
      return state;
  }
}