import Organization from './organization/organization';
// import RolesManagement from './roles/rolesManagement';
import Subscription from './subscription/subscription'
import DashBoard from './dashboard/Dashboard';
import { Strings } from '../../dataProvider/localize';
import Module from './module/ModuleManagement';
// import IndustryManagement from './Industry/IndustryManagement'
// import SWMSManagement from './SWMSManagement/SWMSManagement'
import accessControlManagement from './accessControl/AccessControlManagement';
// import ServiceManagement from './Services/ServiceManagement';
// import CategoryManagement from './Categories/CategoryManagement'
// import SubCategoryManagement from './SubCategories/SubCategoryManagement'
// import LikelyhoodBeforeControl from './LikelyhoodBeforeControl/likelyhoodBeforeControl';
// import ConsequenceBeforeControl from './ConsequenceBeforeControl/consequenceBeforeControl';
// import EmailTemplateManagement from './EmailTemplateManagement/EmailTemplateManagement';
// import AdditionalFieldsContainer from '../v2/AdditionalFields/AdditionalFieldsContainer';

import React from 'react'
import { Icon } from 'antd';
// import LicencesType from './LicencesType/LicencesType';
// import PDFTemplateManagement from './PDFTemplateManagement/PDFTemplateManagement';
// import smsTemplate from './smsTemplate/smsTemplate';
// import InductionManagementModule from './inductionManagementModule/InductionManagementModule';

export const NAV_MENU = [
  {
    title: Strings.ddash_title,
    iconType: () => <Icon type="dashboard" theme="filled"></Icon>,
    linkTo: "/dashboard",
    component: DashBoard,
  },
  {
    title: Strings.org_title,
    iconType: () => <i className="anticon material-icons">account_balance</i>,
    linkTo: "/organization",
    component: Organization,
    description: "Organization"
  },
  // {
  //   title: Strings.role_manage_title,
  //   iconType: () => <i className="anticon material-icons">person</i>,
  //   theme: "filled",
  //   linkTo: "/rolesmanagement",
  //   component: RolesManagement,
  //   description: "Roles Management"
  // },
  {
    title: Strings.sub_title,
    iconType: () => <i className="anticon material-icons">assignment</i>,
    linkTo: "/subscription",
    component: Subscription,
    description: "Subscription"
  },
  {
    title: Strings.module_title,
    iconType: () => <i className="anticon material-icons">build</i>,
    theme: "filled",
    linkTo: "/modulesManagement",
    component: Module
  },
  {
    title: Strings.access_control_title,
    iconType: () => <i className="anticon material-icons">receipt</i>,
    theme: "filled",
    linkTo: "/accessControlsManagement",
    component: accessControlManagement
  },
  // {
  //   title: "Industry Management",
  //   iconType: () => <i className="anticon material-icons">insert_chart</i>,
  //   theme: "filled",
  //   linkTo: "/industryManagement",
  //   component: IndustryManagement
  // },
  // {
  //   title: "Service Management",
  //   iconType: () => <i className="anticon material-icons">group</i>,
  //   theme: "filled",
  //   linkTo: "/serviceManagement",
  //   component: ServiceManagement
  // },
  // {
  //   title: "Category Management",
  //   iconType: () => <i className="anticon material-icons">business</i>,
  //   theme: "filled",
  //   linkTo: "/categoryManagement",
  //   component: CategoryManagement
  // },
  // {
  //   title: "Sub-Category Management",
  //   iconType: () => <i className="anticon material-icons">description</i>,
  //   theme: "filled",
  //   linkTo: "/subCategoryManagement",
  //   component: SubCategoryManagement
  // },
  // {
  //   title: "Likelihood",
  //   iconType: () => <i className="anticon material-icons">assignment_ind</i>,
  //   theme: "filled",
  //   linkTo: "/likelyhoodBeforeContorl",
  //   component: LikelyhoodBeforeControl
  // },
  // {
  //   title: "Consequence",
  //   iconType: () => <i className="anticon material-icons">access_time</i>,
  //   theme: "filled",
  //   linkTo: "/consequnceBeforeControl",
  //   component: ConsequenceBeforeControl
  // },
  // {
  //   title: "Licences Type",
  //   iconType: () => <i className="anticon material-icons">assignment_ind</i>,
  //   theme: "filled",
  //   linkTo: "/licences_type",
  //   component: LicencesType
  // },
  // {
  //   title: "Email Management",
  //   iconType: () => <i className="anticon material-icons">email</i>,
  //   theme: "filled",
  //   linkTo: "/manage_email_templates",
  //   component: EmailTemplateManagement
  // },
  // {
  //   title: "PDF Template Management",
  //   iconType: () => <i className="anticon material-icons">description</i>,
  //   theme: "filled",
  //   linkTo: "/manage_pdf_templates",
  //   component: PDFTemplateManagement
  // },
  // {
  //   title: "SMS Template",
  //   iconType: () => <i className="anticon material-icons">email</i>,
  //   theme: "filled",
  //   linkTo: "/sms_templates",
  //   component: smsTemplate
  // },
  // {
  //   title: "SWMS Management",
  //   iconType: () => <i className="anticon material-icons">event_seat</i>,
  //   theme: "filled",
  //   linkTo: "/SWMS_management",
  //   component: SWMSManagement
  // },
  // {
  //   title: "SMS Template",
  //   iconType: () => <i className="anticon material-icons">email</i>,
  //   theme: "filled",
  //   linkTo: "/sms_templates",
  //   component: smsTemplate
  // },
  // {
  //   title: "Induction Management",
  //   iconType: () => <i className="anticon material-icons">assignment</i>,
  //   theme: "filled",
  //   linkTo: "/induction_management",
  //   component: InductionManagementModule
  // },
  // {
  //   title: "Additional Fields",
  //   iconType: () => <i className="anticon material-icons">assignment</i>,
  //   theme: "filled",
  //   linkTo: "/additional-fields",
  //   component: AdditionalFieldsContainer
  // },
  // {
  //   title: "Email Management",
  //   iconType: () => <i className="anticon material-icons">email</i>,
  //   theme: "filled",
  //   linkTo: "/manage_email_templates",
  //   component: EmailTemplateManagement
  // }
]