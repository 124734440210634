import React from 'react';
import { Icon, Dropdown, notification } from 'antd';
import $ from 'jquery';
import { reduxForm, Field } from 'redux-form';
import { validate } from '../../../utils/Validations/subscriptionValidate';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../common/custom-input';
import { CustomCheckbox } from '../../common/customCheckbox';
import { CustomSelect } from '../../common/customSelect';
import * as actions from '../../../actions/subscriptionAction';
import * as modulesAction from '../../../actions/moduleManagementAction';
import { Strings } from '../../../dataProvider/localize';
import { CustomSwitch } from '../../common/customSwitch';
import {VALIDATE_STATUS} from '../../../dataProvider/constant';
import { handleFocus, DeepTrim } from '../../../utils/common';

class AddSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.props.modulesAction.getModules();
        this.state = { cardExpnadBtn: true, periodOptions: [] };
    }

    onSubmit = async (formData) => {
        formData = await DeepTrim(formData);

        formData.active = +formData.active;
        formData.org_visibility = +formData.org_visibility;
        this.props.action.addSubscription(formData).then((res) => {
            if (res) {
                let finalFormData = new FormData();
                let module_id = []
                Object.keys(formData).forEach(key => {
                    if (key.includes("module_") && formData[key] === true) {
                        module_id.push(key.replace("module_", ""));
                    }
                })
                finalFormData.append("module_id", module_id);
                finalFormData.append("subscription_id", res.subscription_id);
                this.props.action.updateSubscriptionModules(finalFormData).then((flag) => {
                    if (flag) {
                        notification.success({
                            message: Strings.success_title,
                            description: Strings.sub_create_success,
                            onClick: () => { },
                            className: 'ant-success'
                        });
                    }
                    this.props.reset();
                }).catch((error) => {
                    if (error.status === VALIDATE_STATUS) {
                      notification.warning({
                        message: Strings.validate_title,
                        description: error && error.data && typeof error.data.message === 'string'
                          ? error.data.message : Strings.generic_validate,
                        onClick: () => { },
                        className: 'ant-warning'
                      });
                    } else {
                      notification.error({
                        message: Strings.error_title,
                        description: error && error.data && error.data.message && typeof error.data.message === 'string'
                          ? error.data.message : Strings.generic_error,
                        onClick: () => { },
                        className: 'ant-error'
                      });
                    }
                  });
            }

        }).catch((message) => {
            notification.error({
                message: Strings.error_title,
                description: message ? message : Strings.generic_error,
                onClick: () => { },
                className: 'ant-error'
            });
        });
    }

    // expand center card----------
    handleExpand = () => {
        this.setState({ cardExpnadBtn: !this.state.cardExpnadBtn })
        $(".main-container").toggleClass("po-relative");
        $(".sf-card-wrap").toggleClass("fullscreen");
        $(".zoom-save-bnt").toggleClass("show-bnt");
    }

    render() {
        const { handleSubmit } = this.props;
        const periodOptions = [{ title: 'Monthly', value: '1' }, { title: 'Quarterly', value: '3' }, { title: 'Yearly', value: '12' }]
        return (
            <div className={this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"}>
                <div className="row">
                    <div className="col-md-12 col-lg-8">
                        <form onSubmit={handleSubmit(this.onSubmit)}>
                            <div className="sf-card-wrap">
                                {/* zoom button  */}
                                <div className="card-expands">
                                    <button type="button" onClick={this.handleExpand} className="exapnd-bnt normal-bnt">
                                        <Icon type={this.state.cardExpnadBtn ? "fullscreen" : "fullscreen-exit"} /></button>
                                </div>
                                <div className="sf-card">
                                    <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                        <h2 className="sf-pg-heading">{Strings.sub_hd_title}</h2>
                                        <div className="info-btn disable-dot-menu">
                                            <Dropdown className="more-info" disabled overlay={''}>
                                                <i className="material-icons">more_vert</i>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="sf-card-body mt-2">
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label={Strings.sub_f_name}
                                                name="name"
                                                placeholder={Strings.name_subsc}
                                                type="text"
                                                id="name"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form w-currency-symbl">
                                            <Field
                                                label={Strings.sub_f_amount}
                                                name="amount"
                                                placeholder={Strings.amount_subsc}
                                                type="text"
                                                id="amount"
                                                component={customInput} />
                                        </fieldset>
                                        <fieldset className="form-group sf-form">
                                            <Field
                                                label="Subscription Period"
                                                name="period"
                                                placeholder={Strings.period_subsc}
                                                options={periodOptions}
                                                component={CustomSelect} />
                                        </fieldset>
                                        <div className="row">
                                            <div className="col-3">
                                                <fieldset className="form-group sf-form">
                                                    <Field
                                                        name="active"
                                                        id="active"
                                                        label={Strings.subscript_status}
                                                        component={CustomSwitch} />
                                                </fieldset>
                                            </div>
                                            <div className="col-6">
                                                <fieldset className="form-group sf-form">
                                                    <Field
                                                        name="org_visibility"
                                                        label={Strings.subscript_visibility}
                                                        component={CustomSwitch} />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*  */}
                                <div className="sf-card mt-4">
                                    <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                        <h2 className="sf-pg-heading">{"Subscription Modules"}</h2>
                                        <div className="info-btn disable-dot-menu">
                                            <Dropdown className="more-info" disabled overlay={''}>
                                                <i className="material-icons">more_vert</i>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="sf-card-body">
                                        <div className="sf-roles-group add-sub-mod sub-3">
                                            {this.props.modules && this.props.modules.length ? this.props.modules.map((modulesName,index) => {
                                                return <Field key={index}
                                                    name={modulesName ? "module_" + modulesName.id.toString() : ''}
                                                    label={modulesName && modulesName.module_name ? modulesName.module_name : ''} component={CustomCheckbox} />
                                            }) : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* zoom save button  */}
                                <div className="row zoom-save-bnt">
                                    <div className="col-md-12">
                                        <div className="all-btn d-flex justify-content-end mt-4">
                                            <div className="btn-hs-icon">
                                                <button type="submit" className="bnt bnt-active">
                                                    <i className="material-icons">save</i> {Strings.save_btn}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  */}

                            <div className="all-btn d-flex justify-content-end mt-4">
                                <div className="btn-hs-icon">
                                    <button type="submit" className="bnt bnt-active">
                                        <i className="material-icons">save</i> {Strings.save_btn}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscription.subscriptions,
        modules: state.moduleManagement.modules,
        initialValues: {
            active: true,
            period: [],
        }
    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
        modulesAction: bindActionCreators(modulesAction, dispatch)
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: 'addSubscription', validate, onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(AddSubscription)
