import {
  GET_ACCESS_CONTROL,
  ACCESS_CONTROL_URL,
  GET_ACCESS_CONTROL_BY_MODULE,
  INIT_ACCESS_CONTROLS_LIST,
  GET_ACCESS_CONTROLS_BY_EXPAND,
  GET_ACCESS_CONTROLS_BY_SEARCH,
} from '../dataProvider/constant';
import {
  startSipnner, stopSipnner, startMiniSpinner, startScrollSpinner, stopMiniSpinner, stopScrollSpinner
} from '../utils/spinner';
import axiosInstance from '../dataProvider/axiosHelper';
import { Strings } from '../dataProvider/localize';

const getAllAccessControlApi = dispatch => {
  return axiosInstance.get(ACCESS_CONTROL_URL + '?show_list=1')
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_ACCESS_CONTROL,
          payload: res.data.data.access_controls
        })
        return Promise.resolve(true)
      }

    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

const getAllAccessControlByModuleApi = dispatch => {
  return axiosInstance.get(ACCESS_CONTROL_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_ACCESS_CONTROL_BY_MODULE,
          payload: res.data.data.access_controls
        })
        return Promise.resolve(true)
      }

    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const getAccessControlsByModule = () => dispatch => {
  startSipnner(dispatch);
  return getAllAccessControlByModuleApi(dispatch);

}

export const getAccessControls = () => dispatch => {
  startSipnner(dispatch);
  return getAllAccessControlApi(dispatch);
}


export const addAccessControl = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(ACCESS_CONTROL_URL, formData)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        initAllAccessControlsApi(dispatch)
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateAccessControl = formData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(ACCESS_CONTROL_URL, formData)
    .then(res => {
      stopSipnner(dispatch)
      if (res.data.status) {
        getAllAccessControlApi(dispatch)
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const initAccessControls = () => dispatch => {
  return initAllAccessControlsApi(dispatch)
}

const initAllAccessControlsApi = dispatch => {
  startSipnner(dispatch)

  const searchKey = '';
  const pageNumber = 1;

  const url = `${ACCESS_CONTROL_URL}?show_list=1&search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: INIT_ACCESS_CONTROLS_LIST,
          payload: res.data.data.access_controls
        })
        return Promise.resolve()
      }
    })
    .catch(error => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}

export const searchExpandAccessControlsList = (searchKey = '', pageNumber = 1, searching, scrolling) => dispatch => {

  searching ? startMiniSpinner(dispatch) : scrolling ? startScrollSpinner(dispatch) : startSipnner(dispatch)

  const url = `${ACCESS_CONTROL_URL}?show_list=1&search_key=${searchKey}&page=${pageNumber}`

  return axiosInstance.get(url)
    .then(res => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      if (res.data.status && !searching) {
        dispatch({
          type: GET_ACCESS_CONTROLS_BY_EXPAND,
          payload: res.data.data.access_controls,
        })
        return Promise.resolve(res.data.data.access_controls.length)
      } else {
        dispatch({
          type: GET_ACCESS_CONTROLS_BY_SEARCH,
          payload: res.data.data.access_controls,
        })
        return Promise.resolve(res.data.data.access_controls.length)
      }
    })
    .catch(error => {
      searching ? stopMiniSpinner(dispatch) : scrolling ? stopScrollSpinner(dispatch) : stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    })
}
